import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { DeleteattendanceRequest, UpdateattendanceRequest, AddattendanceRequest, GetattendanceRequest, FetchattendanceRequest, GetTeacherRequest } from '../redux/reducer/FetchCompanyDataReducer'
import Swal from "sweetalert2";
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';
import Loader from '../utils/Loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ApiConnection from '../utils/ApiConnection';
import Select from 'react-select';
import Accordion from 'react-bootstrap/Accordion';
import ReactSwitch from 'react-switch';
import { debounce } from 'lodash';


const Attendance_List = () => {

  const [search, setsearch] = useState("")
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [editrowId, seteditrowId] = useState(null)
  const [studId, setstudId] = useState(null)
  const [trainingmodule, settrainingmodule] = useState("")
  const [trainingmodule1, settrainingmodule1] = useState("")
  const [clockin, setclockin] = useState("")
  const [clockin1, setclockin1] = useState("")
  const [clockout, setclockout] = useState("")
  const [clockout1, setclockout1] = useState("")
  const [punchdate, setpunchdate] = useState("")
  const [punchdate1, setpunchdate1] = useState("")
  const [remarks, setremarks] = useState("")
  const [remarks1, setremarks1] = useState("")
  const [systemin, setsystemin] = useState("")
  const [systemin1, setsystemin1] = useState("")
  const [systemout, setsystemout] = useState("")
  const [systemout1, setsystemout1] = useState("")
  const [studentList, setstudentList] = useState([])
  const [teacherList, setteacherList] = useState([])
  const [AttendanceDetails, setAttendanceDetails] = useState([])
  const [selectedStudentId, setSelectedStudentId] = useState("");
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [attendanceData, setAttendanceData] = useState([]);
  const [isPresent, setIsPresent] = useState(false);
  const [filteredAttendanceData, setFilteredAttendanceData] = useState([]);
  const [Attendancelist, setAttendancelist] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editableClockIn, setEditableClockIn] = useState("");
  const [editableClockOut, setEditableClockOut] = useState("");
  const [editableRemarks, setEditableRemarks] = useState("");
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [editableAttendance, setEditableAttendance] = useState({});
  const [selectedAttendance, setSelectedAttendance] = useState(null); // For storing the currently selected attendance item
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [currentStudentId, setCurrentStudentId] = useState(null);
  const [currentScheduleId, setCurrentScheduleId] = useState(null); // Add a new state to store the current schedule ID


  console.log(attendanceData)

  const handleShow = () => setShow(true);
  const [FilterResult, setFilterResult] = useState([])

  let navigate = useNavigate()
  const { id } = useParams()



  const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
  var userinfo = JSON.parse(localStorage.getItem('userinfo'))
  var attendanceList = fetchacompanyReducer?.GetattendanceResponse?.results[0]
  var attendanceView = fetchacompanyReducer?.FetchattendanceResponse?.results[0]
  var studentteacherList = fetchacompanyReducer?.GetTeacherResponse?.results

  const dispatch = useDispatch()

  const handleClose = () => {
    setShow(false);
    resetAddForm();
  };

  const handleClose1 = () => {
    setShow1(false);
    resetEditForm();
  };

  const resetAddForm = () => {
    setpunchdate("");
    setclockin("");
    setclockout("");
    setremarks("");
    setIsPresent(false);
  };

  const resetEditForm = () => {
    setEditableAttendance({});
    setSelectedAttendance(null);
  };

  useEffect(() => {
    dispatch(GetTeacherRequest());
    fetchAttendanceData();
  }, [dispatch]);


  useEffect(() => {
    var editinfo = fetchacompanyReducer?.FetchattendanceResponse?.results[0]
    console.log(editinfo)

    const convertedTime = () => {
      const hours = Math.floor(clockin1 / 3600000);
      console.log("hours", hours)
      const minutes = Math.floor((clockin1 % 3600000) / 60);
      setclockin1(`${hours} hours ${minutes} minutes`);
    }

    setclockin1(editinfo?.login_time)
    setclockout1(editinfo?.logout_time)
    setpunchdate1(editinfo?.attendance_date)
    setremarks1(editinfo?.remarks)
  }, [fetchacompanyReducer?.FetchattendanceResponse])

  const GamePlanHandle = (tid, date, pros, pros2) => {
    console.log("GamePlanHandle", tid, date, pros, pros2);

    const baseUrl = `${window.location.origin}/${userinfo.company_slug}/#/${userinfo.company_slug}`;
    const fullUrl = `${baseUrl}/DigitalWorkoutPlan/${tid}/${date}/${pros}/${pros2}`;
    window.open(fullUrl, "_blank");
  };

  const DeleteHandle = (did) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeleteattendanceRequest({ student_id: id, id: did }))

        if (fetchacompanyReducer?.DeleteattendanceResponse) {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
        }
      }
    })
  }

  useEffect(() => {
    fetchAttendanceData();
  }, [selectedDate, selectedEmployeeId, selectedStudent]);

  const fetchAttendanceData = async () => {
    const response = await ApiConnection.get(
      `employee-list/attendance/api/?server_type=production&attandance_date=${selectedDate}&employee_id=${selectedEmployeeId ? selectedEmployeeId.value : ''}&student_id=${selectedStudent ? selectedStudent.value : ''}&status=${selectedStatus}`
    );

    if (response?.status === 200) {
      const results = response.data.results[0];
      setAttendanceData(results);
      // Pre-fill editableAttendance based on the fetched data
      const newEditableAttendance = {};
      if (results) {
        results.forEach(attendance => {
          attendance.student_list.forEach(student => {
            student.attendance_list.forEach(attendanceItem => {
              if (attendanceItem.id) {
                newEditableAttendance[student.id] = {
                  ...(newEditableAttendance[student.id] || {}),
                  [attendanceItem.schedule_id]: {
                    clockin: attendanceItem.login_time || "",
                    clockout: attendanceItem.logout_time || "",
                    remarks: attendanceItem.remarks || "",
                    attendanceId: attendanceItem.id // Store the attendance ID
                  }
                };
              }
            });
          });
        });
      }
      setEditableAttendance(newEditableAttendance);
    }
  };

  const handleEmployeeChange = (selectedEmployee) => {
    setSelectedEmployeeId(selectedEmployee);
    setSelectedStudent(null);
    if (selectedEmployee.value === '') {
      setSelectedEmployeeId(null);
    }
    fetchAttendanceData();
  };

  const GetStudent = async () => {
    try {
      const response = await ApiConnection.get(`student-crud/?server_type=production`)
      if (response?.status == 200) {
        setstudentList(response?.data?.results)
      }
    } catch (e) { }
  }

  useEffect(() => {
    const GetTeacher = async () => {
      try {
        const response = await ApiConnection.get(`employee-time-schedule/emp-list/?server_type=production&date=${selectedDate}`);
        if (response?.status === 200) {
          setteacherList(response?.data?.results);
        }
      } catch (e) { }
    };

    if (selectedDate) {
      GetTeacher();
    }
  }, [selectedDate]);

  useEffect(() => {
    GetStudent()
  }, [])

  const objectStyle = {
    backgroundColor: "rgb(215 255 201)",
  };

  const objectStyleValid = {
    backgroundColor: "white",
  };


  const handleStudentRowClick = (student, attendanceItem) => {
    // console.log(student, 'attendanceItem', attendanceItem);
    // setstudId(student.id);
    // const attendanceData = student?.attendance_list?.find(item => item.id !== "");
    // console.log('attendanceData', attendanceData);

    // Store the selected attendance item in the editableAttendance state
    setEditableAttendance(prevState => ({
      ...prevState,
      [student.id]: {
        clockin: prevState[student.id]?.clockin || "",
        clockout: prevState[student.id]?.clockout || "",
        remarks: prevState[student.id]?.remarks || "",
      },
    }));
  };

  const handleClockInChange = (studentId, scheduleId, value) => {
    setEditableAttendance(prevState => ({
      ...prevState,
      [studentId]: {
        ...prevState[studentId],
        [scheduleId]: {
          ...prevState[studentId]?.[scheduleId],
          clockin: value,
        },
      },
    }));
  };

  const handleClockOutChange = (studentId, scheduleId, value) => {
    setEditableAttendance(prevState => ({
      ...prevState,
      [studentId]: {
        ...prevState[studentId],
        [scheduleId]: {
          ...prevState[studentId]?.[scheduleId],
          clockout: value,
        },
      },
    }));
  };

  const handleRemarksChange = (studentId, scheduleId, value) => {
    setEditableAttendance(prevState => ({
      ...prevState,
      [studentId]: {
        ...prevState[studentId],
        [scheduleId]: {
          ...prevState[studentId]?.[scheduleId],
          remarks: value,
        },
      },
    }));
  };

  const isCompleteEnabled = editableClockIn && editableClockOut;

  const AddHandle = () => {
    if (clockin == '') {
      Swal.fire({
        icon: "error",
        text: 'Clock In should be must',
      });
    } else {
      let obj = {
        "attendance_date": punchdate,
        "login_time": clockin,
        "logout_time": clockout,
        "remarks": remarks,
        "is_present": isPresent,
      }
      dispatch(AddattendanceRequest({ obj: obj, student_id: id, navigate: navigate }))
    }
  }

  const teacherOptions = [
    { value: '', label: 'Select Teacher' }, // Add a blank option for selecting all teachers
    ...teacherList?.map(teacher => ({
      value: teacher.employee_id,
      label: `${teacher.teacher_first_name} ${teacher.teacher_last_name}`,
    })),
  ];

  const studentOptions = [
    { value: '', label: 'Select Student' }, // Add a blank option for selecting all students
    ...studentList.map(student => ({
      value: student.id,
      label: `${student.first_name} ${student.last_name}`,
    })),
  ];

  // const UpdateHandle = async (studentId, attendanceId, updateData) => {
  //   try {
  //     let response;
  //     if (!attendanceId) {
  //       response = await ApiConnection.post(`attendance-crud/?server_type=production&student_id=${studId}`, {
  //         ...updateData,
  //         student_id: studentId,
  //       });
  //     } else {
  //       response = await ApiConnection.put(`attendance-crud/?server_type=production&method=edit&student_id=${studId}&id=${attendanceId}`, {
  //         ...updateData,
  //         student_id: studentId,
  //       });
  //     }

  //     if (response?.status === 200) {
  //       Swal.fire('Success!', 'Attendance updated successfully!', 'success');
  //       fetchAttendanceData();
  //     } else {
  //       Swal.fire('Error!', 'Failed to update attendance.', 'error');
  //     }
  //   } catch (error) {
  //     console.error("Error updating attendance:", error);
  //     Swal.fire('Error!', 'An error occurred while updating attendance.', 'error');
  //   }
  // };

  const handleComplete = async (studentId, scheduleId) => {
    const attendanceData = editableAttendance[studentId]?.[scheduleId];
    if (!attendanceData) return; // Ensure there is attendance data for the student

      // Check if clockout is available
      if (!attendanceData.clockout) {
        // Set default remarks to "No Show"
    attendanceData.remarks = "No Show";
    setEditableAttendance(prevState => ({
        ...prevState,
        [studentId]: {
            ...prevState[studentId],
            [scheduleId]: {
                ...prevState[studentId]?.[scheduleId],
                remarks: "No Show", // Update the remarks in the editableAttendance state
            },
        },
    }));
    // Open confirmation modal if clockout is not available
    setSelectedStudentId(studentId); // Store selected student ID for API call
    setCurrentStudentId(studentId); // Store current student ID for confirmation
    setCurrentScheduleId(scheduleId); // Store the current schedule ID for confirmation
    setShowConfirmationModal(true);
    return;
  }

    const updateData = {
      attendance_date: moment().format('YYYY-MM-DD'),
      login_time: attendanceData.clockin,
      logout_time: attendanceData.clockout || null,
      remarks: attendanceData.remarks,
      schedule_id: scheduleId,
    };

    try {
      let response;
      if (attendanceData.attendanceId) {
        // If attendanceId is available, use PUT
        response = await ApiConnection.put(`attendance-crud/?server_type=production&method=edit&student_id=${studentId}&id=${attendanceData.attendanceId}`, updateData);
      } else {
        // If attendanceId is not available, use POST
        response = await ApiConnection.post(`attendance-crud/?server_type=production&student_id=${studentId}`, updateData);
      }

      if (response?.status === 200) {
        Swal.fire('Success!', 'Attendance updated successfully!', 'success');
        fetchAttendanceData();
      } else {
        Swal.fire('Error!', 'Failed to update attendance.', 'error');
      }
    } catch (error) {
      console.error("Error updating attendance:", error);
      Swal.fire('Error!', 'An error occurred while updating attendance.', 'error');
    }
  };

  const handleStatusChange = (event) => {
    const value = event.target.value; // Get the selected value
    setSelectedStatus(value); // Update the state with the selected value
    fetchAttendanceData(); // Fetch attendance data based on the new status
  };

  const debounceUpdateAttendance = debounce(async (attendanceItem, value, type) => {
    const updateData = {
      attendance_date: selectedDate,
      [type]: value,
      remarks: editableAttendance[attendanceItem.id]?.remarks || attendanceItem.remarks || "",
    };

    const scheduleId = attendanceItem.schedule_id;
    await UpdateHandle(attendanceItem.student_id, attendanceItem.id, updateData, scheduleId);
  }, 500);

  const UpdateHandle = async (studentId, attendanceId, updateData, scheduleId) => {
    try {
      let response;
      if (!attendanceId) {
        response = await ApiConnection.post(`attendance-crud/?server_type=production&student_id=${studId}`, {
          ...updateData,
          student_id: studentId,
          schedule_id: scheduleId,
        });
      } else {
        response = await ApiConnection.put(`attendance-crud/?server_type=production&method=edit&student_id=${studId}&id=${attendanceId}`, {
          ...updateData,
          student_id: studentId,
          schedule_id: scheduleId,
        });
      }

      if (response?.status === 200) {
        Swal.fire('Success!', 'Attendance updated successfully!', 'success');
        fetchAttendanceData();
      } else {
        Swal.fire('Error!', 'Failed to update attendance.', 'error');
      }
    } catch (error) {
      console.error("Error updating attendance:", error);
      Swal.fire('Error!', 'An error occurred while updating attendance.', 'error');
    }
  };

  const handleStudentChange = (selectedStudent) => {
    setSelectedStudent(selectedStudent);

    // If the selected student is blank, set it to null to fetch all attendance data
    if (selectedStudent.value === '') {
      setSelectedStudent(null);
    }

    fetchAttendanceData();
  };

  const handleConfirmation = async (confirm) => {
    if (confirm) {
      const attendanceData = editableAttendance[currentStudentId]?.[currentScheduleId];
              // Check if both clockin and clockout are missing
              if (!attendanceData.clockin && !attendanceData.clockout) {
                attendanceData.remarks = "No Show"; // Ensure remarks are set to "No Show"
            }
      const updateData = {
        attendance_date: moment().format('YYYY-MM-DD'),
        login_time: attendanceData?.clockin,
        logout_time: attendanceData?.clockout || null,
        remarks: attendanceData?.remarks || "No Show",
        schedule_id: currentScheduleId,
      };
    
      try {
        let response;
        if (attendanceData?.attendanceId) {
          // If attendanceId is available, use PUT
          response = await ApiConnection.put(`attendance-crud/?server_type=production&method=edit&student_id=${currentStudentId}&id=${attendanceData.attendanceId}`, updateData);
        } else {
          // If attendanceId is not available, use POST
          response = await ApiConnection.post(`attendance-crud/?server_type=production&student_id=${currentStudentId}`, updateData);
        }
    
        if (response?.status === 200) {
          Swal.fire('Success!', 'Attendance updated successfully!', 'success');
          fetchAttendanceData();
        } else {
          Swal.fire('Error!', 'Failed to update attendance.', 'error');
        }
      } catch (error) {
        console.error("Error updating attendance:", error);
        Swal.fire('Error!', 'An error occurred while updating attendance.', 'error');
      }

    }
    setShowConfirmationModal(false); // Close the modal
  };


  return (
    <div className='common-layout'>
      <Loader visible={fetchacompanyReducer.isloading} />

      <div className='innerlayout p-3'>
        <div className='mb-4'>
          <h3>Attendance Management</h3>
        </div>

        <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-67px' }}>
          <ul>
            <li>
              <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
            </li>
          </ul>
        </div>

        <div className='row form-style mb-4'>
          <div className='col-lg-3'>
            <div className='form-group'>
              <label className='selectlabel'>Select Date</label>
              <input
                type='date'
                className='form-control'
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
              />
            </div>
          </div>

          <div className='col-lg-3'>
            <div className='form-group'>
              <label className='selectlabel'>Select Teacher</label>
              <Select
                options={teacherOptions}
                value={selectedEmployeeId ? { value: selectedEmployeeId.value, label: teacherOptions.find(emp => emp.value === selectedEmployeeId.value)?.label } : ''}
                onChange={handleEmployeeChange}
                placeholder="Search Teacher"
              />
            </div>
          </div>
          <div className='col-lg-3'>
            <div className='form-group'>
              <label className='selectlabel'>Select Student</label>
              <Select
                options={studentOptions}
                value={selectedStudent}
                onChange={handleStudentChange} // Use the modified handleStudentChange
                placeholder="Search Student"
              />
            </div>
          </div>

          <div className='col-lg-3'>
  <div className='form-group'>
    <label className='selectlabel'>Select Status</label>
    <select
      className='form-control'
      value={selectedStatus}
      onChange={handleStatusChange} // Add onChange handler
    >
      <option value="">Select Status</option> {/* Default option */}
      <option value="0">In Progress</option>
      <option value="1">Completed</option>
      <option value="2">No Show</option>
    </select>
  </div>
</div>

          {/* <div className='col-lg-3'>
            <div className='form-group'>
              <label className='selectlabel'>Select Status</label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <ReactSwitch
                  checked={isPresent}
                  onChange={setIsPresent}
                  offColor="#888"
                  onColor="#0f0"
                />
                <span style={{ marginLeft: '10px' }}>
                  {isPresent ? 'Completed' : 'Is-Present'}
                </span>
              </div>
            </div>
          </div> */}
        </div>


        <Accordion className='view-accordian' style={{ display: "grid", gap: "10px" }}>
          {attendanceData?.length > 0 ? (
            attendanceData.map((attendance, index) => (
              <Accordion.Item eventKey={attendance?.id?.toString()} key={attendance.id}>
                <Accordion.Header style={{ backgroundColor: 'lightgray', padding: '0px 15px' }}>
                  {`${attendance?.employee_first_name} ${attendance.employee_last_name}`}
                </Accordion.Header>
                <Accordion.Body>
                  <div className='table-responsive'>
                    <table className='custom-table'>
                      <thead>
                        <tr className='text-center'>
                          <th>Student Name</th>
                          <th>Scheduled Time</th>
                          <th>Clock In</th>
                          <th>Clock Out</th>
                          <th>Duration (in min.)</th>
                          <th>Remarks</th>
                          {userinfo?.user_type === "COMPANY" && <th style={{ padding: '10px 0px' }}>Action</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {attendance?.student_list?.length > 0 ? (
                          attendance?.student_list?.map((student, i) => (
                            <tr key={student?.id}>
                              <td className='text-center'>{student.std_first_name} {student.std_last_name}
                              </td>
                              <td className='text-center'>
                                {student.attendance_list?.map((attendanceItem, index) => (
                                  <div key={attendanceItem.schedule_id}>
                                    <span className='timeseparate'>{attendanceItem.std_service_start_time} - {attendanceItem.std_service_end_time}</span>
                                  </div>
                                ))}
                              </td>
                              <td className='p-0 text-center'>
                                {student.attendance_list.map(attendanceItem => (
                                  <div key={attendanceItem.schedule_id}>
                                    <input
                                      type="time"
                                      value={editableAttendance[student.id]?.[attendanceItem.schedule_id]?.clockin || ""}
                                      onChange={(e) => handleClockInChange(student.id, attendanceItem.schedule_id, e.target.value)}
                                    />
                                  </div>
                                ))}
                              </td>
                              <td className='p-0 text-center'>
                                {student.attendance_list.map(attendanceItem => (
                                  <div key={attendanceItem.schedule_id}>
                                    <input
                                      type="time"
                                      value={editableAttendance[student.id]?.[attendanceItem.schedule_id]?.clockout || ""}
                                      onChange={(e) => handleClockOutChange(student.id, attendanceItem.schedule_id, e.target.value)}
                                    />
                                  </div>
                                ))}
                              </td>
                              <td className='p-0 text-center'>
                                {student.attendance_list.map(list => {
                                  const loginTime = list.login_time ? new Date(`1970-01-01T${list.login_time}:00`) : null;
                                  const logoutTime = list.logout_time ? new Date(`1970-01-01T${list.logout_time}:00`) : null;
                                  let durationInMinutes = 0;
                                  if (loginTime && logoutTime) {
                                    durationInMinutes = Math.round((logoutTime - loginTime) / 60000);
                                  }
                                  return <div key={list.id}><span className='timeseparate'>{durationInMinutes} min</span></div>;
                                })}
                              </td>
                              <td className='p-0 text-center'>
                                {student.attendance_list.map(attendanceItem => (
                                  <div key={attendanceItem.schedule_id}>
                                    <input
                                      type="text"
                                      value={editableAttendance[student.id]?.[attendanceItem.schedule_id]?.remarks || ""}
                                      onChange={(e) => handleRemarksChange(student.id, attendanceItem.schedule_id, e.target.value)}
                                    />
                                  </div>
                                ))}
                              </td>
                              {userinfo?.user_type === "COMPANY" && (
                                <td className='text-center' style={{ padding: '10px 0px' }}>
                                  {student.attendance_list.map(list => (
                                    <span className='timeseparate' style={{ padding: '10px 10px' }}>
                                      <button className='table-btn text-info' onClick={() => GamePlanHandle(student.id, list.attendance_date, list.login_time, list.logout_time)}>Game Plan</button>
                                      {/* <NavLink className='mr-2' onClick={() => handleShow1(list.id, item.attendance_date, item.student_id)}><i className="fa-solid fa-pen-to-square"></i></NavLink> */}
                                      {/* <button onClick={() => DeleteHandle(list.id)} className='table-btn'><i className="fa-solid fa-trash"></i></button> */}
                                      <button key={list.schedule_id} className='table-btn' style={{ background: '#83BEDF', color: '#fff', padding: '5px 10px' }} onClick={() => handleComplete(student.id, list.schedule_id)}>Complete</button>
                                    </span>
                                  ))}
                                </td>
                              )}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={userinfo?.user_type === "COMPANY" ? 7 : 4} className='text-center'>No data found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))
          ) : (
            <div className='text-center'>No attendance data available for the selected date.</div>
          )}
        </Accordion>

      </div>


      <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)} size="sm">
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          Is this Student a "No-Show" ?
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-secondary' onClick={() => setShowConfirmationModal(false)}>No</button>
          <button className='btn btn-primary' onClick={() => handleConfirmation(true)}>Yes, Submit</button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}

export default Attendance_List