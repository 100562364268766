import {takeEvery,takeLatest, call, put, select } from 'redux-saga/effects'
import { getApi, postApi, getApiWithParam, putApi, patchApi } from '../../utils/ApiRequest';
import Swal from "sweetalert2";

import {
  SignInSuccess,
  SignInFailure,
  GetCompanySuccess,
  GetCompanyFailure,
  AddCompanySuccess,
  AddCompanyFailure,
  EditCompanySuccess,
  EditCompanyFailure,
  UpdateCompanySuccess,
  UpdateCompanyFailure,
  DeleteCompanySuccess,
  DeleteCompanyFailure,
  SendEmaillinkSuccess,
  SendEmaillinkFailure,
  CreatepasswordSuccess,
  CreatepasswordFailure,
  GetProspectiveSuccess,
  GetProspectiveFailure

} from '../reducer/FetchAdminDataReducer';

const token = sessionStorage.getItem('mtb_access_token');

function* LoginRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
    };

    try {
      //let response = yield call(() => axios.post(`${Constant.BASE_URL}/login`, action, header));
      let response = yield call(postApi, 'users/login/', action.payload.obj, header);
      console.log(response?.data?.data);
      if(response.data.success){
        if (response?.data?.data?.token != null) {
          sessionStorage.setItem('mtb_access_token', response?.data?.data?.token);
          localStorage.setItem('userinfo', JSON.stringify(response?.data?.data));
        }
        yield put(SignInSuccess(response.data))
        action.payload.navigate("/admin/dashboard")
        window.location.reload();
      } else {
        yield put(SignInFailure(response.data))
        Swal.fire({
          icon: "error",
          text: response.data?.message,
        });
      }
      

    } catch(err){
      yield put(SignInFailure(err.data))

      Swal.fire({
        icon: "error",
        text: err.response?.data?.detail,
      });
    }

  
}

function* GetCompanyRequest() {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
      accesstoken:token
    };

    try {

      let response = yield call(getApi, 'company/company_crud/', header);

      if(response.status === 200) {
        yield put(GetCompanySuccess(response.data))
      } else {
        yield put(GetCompanyFailure(response.data))
      }

    } catch(err){
      
    }

  
}

function* AddCompanyRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
      accesstoken:token
    };

    try {
      let response = yield call(postApi, 'company/company_crud/?server_type=production', action.payload.obj, header);
        if(response?.data?.status === 400){
          yield put(AddCompanyFailure(response.data))
            var rawstring = JSON.stringify(response?.data?.results?.Data);
            var _is = rawstring.includes("email")
          if(_is){
            Swal.fire({
              icon: "error",
              text: response?.data?.results?.Data.email[0],
            });
          } else {
            Swal.fire({
              icon: "error",
              text: response?.data?.msg,
            });
          }
        
        } else {
          Swal.fire({
            icon: "success",
            text: response?.data?.msg,
          });
          yield put(AddCompanySuccess(response.data))
          action.payload.navigate("/admin/Add-company/confirmation")
        }
       
    } catch(err){
      yield put(AddCompanyFailure(err.data))
      Swal.fire({
        icon: "error",
        text: err.response?.data?.detail,
      });
    }

  
}

function* EditCompanyRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
      accesstoken:token
    };

    try {
      let response = yield call(getApi, `company/company_crud/?id=${action.payload.id}`, header);
      if(response?.status == 200){
        yield put(EditCompanySuccess(response.data))
      } else {
        yield put(EditCompanyFailure(response.data))
      }
       
    } catch(err){
      yield put(EditCompanyFailure(err.data))
    }
  
}

function* UpdateCompanyRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
      accesstoken:token
    };

    try {
      let response = yield call(putApi, `company/company_crud/?id=${action.payload.pharam.id}&method=${action.payload.pharam.method}`, action.payload.obj,  header);
      console.log(response)
      if(response?.status == 200){
        if(response?.data?.status == 400){
          yield put(UpdateCompanyFailure(response.data))
          Swal.fire({
            icon: "error",
            text: response?.data?.msg,
          });
        } else {
          Swal.fire({
            icon: "success",
            text: response?.data?.msg,
          });
          yield put(UpdateCompanySuccess(response.data))
          action.payload.navigate("/admin/companyList")
        }
        
      } else {
        yield put(UpdateCompanyFailure(response.data))
      }
       
    } catch(err){
      yield put(UpdateCompanyFailure(err.data))
    }
  
}

function* DeleteCompanyRequest(action) {

  let header = {
      accesstoken:token
    };


    try {
     
      let response = yield call(getApiWithParam, `company/company_crud/?id=${action.payload.id}&method=${action.payload.method}`, header);
      console.log('delete a company', response);
      if(response?.status == 200){
        yield put(DeleteCompanySuccess(response.data))
      } else {
        yield put(DeleteCompanyFailure(response.data))
      }
       
    } catch(err){
      console.log(err)
      yield put(DeleteCompanyFailure(err.data))
    }
  
}

function* SendEmaillinkRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
    };

    try {
      let response = yield call(postApi, 'users/request-reset-email/', action.payload.obj, header);
        console.log(response);
        if(!response?.status === 200){
          yield put(SendEmaillinkFailure(response.data))
        
        } else {
          Swal.fire({
            icon: "success",
            text: response?.data?.success,
          });
          yield put(SendEmaillinkSuccess(response.data))
          action.payload.navigate("/admin/login")
        }
       
    } catch(err){
      yield put(SendEmaillinkFailure(err))
      Swal.fire({
        icon: "error",
        text: err?.response?.data?.message,
      });
    }

  
}

function* CreatePasswordRequest(action) {

  let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
    };

    try {
      let response = yield call(patchApi, 'users/password-reset-complete/', action.payload.obj, header);

        if(!response?.status === 200){
          yield put(CreatepasswordFailure(response.data))
        
        } else {
          Swal.fire({
            icon: "success",
            text: response?.data?.message ,
          });
          yield put(CreatepasswordSuccess(response.data))
          const slug = response.data.slug; // Assuming slug is present in the response data

          action.payload.navigate(`/${slug}/login`)
        }
       
    } catch(err){
      yield put(CreatepasswordFailure(err))
      Swal.fire({
        icon: "error",
        text: err?.response?.data?.message,
      });
    }

  
}




const watchFunction = [
 
  (function* () {
    yield takeLatest('FetchAdminDataReducer/LoginRequest', LoginRequest)
  })(),

  (function* () {
    yield takeLatest('FetchAdminDataReducer/GetCompanyRequest', GetCompanyRequest)
  })(),
 
  (function* () {
    yield takeLatest('FetchAdminDataReducer/AddCompanyRequest', AddCompanyRequest)
  })(),

  (function* () {
    yield takeLatest('FetchAdminDataReducer/EditCompanyRequest', EditCompanyRequest)
  })(),

  (function* () {
    yield takeLatest('FetchAdminDataReducer/UpdateCompanyRequest', UpdateCompanyRequest)
  })(),

  (function* () {
    yield takeLatest('FetchAdminDataReducer/DeleteCompanyRequest', DeleteCompanyRequest)
  })(),

  (function* () {
    yield takeLatest('FetchAdminDataReducer/SendEmaillinkRequest', SendEmaillinkRequest)
  })(),

  (function* () {
    yield takeLatest('FetchAdminDataReducer/CreatePasswordRequest', CreatePasswordRequest)
  })(),

 

  
  

  ];




export default watchFunction;