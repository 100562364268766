import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { GetTeacherRequest, DeleteTeacherRequest, UpdateTeacherRequest, AddTeacherRequest, FetchTeacherRequest, GetSubjectRequest, FetchstudentRequest, GetSchoolRequest, FetchSchoolRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Swal from "sweetalert2";
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';
import Loader from '../../utils/Loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment, { min } from "moment";
import ApiConnection from '../../utils/ApiConnection';

const StudentTeacher = ({ id, etype, schl_id }) => {

  console.log('schl_id',schl_id)

  const [search, setsearch] = useState("")
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [editrowId, seteditrowId] = useState(null)
  const [detailsrowId, setdetailsrowId] = useState(null)
  const [show2, setShow2] = useState(false);
  const [teachername, setteachername] = useState("")
  const [teachername1, setteachername1] = useState("")
  const [subject, setsubject] = useState("")
  const [mobile, setmobile] = useState("")
  const [email, setemail] = useState("")
  const [addressone, setaddressone] = useState("")
  const [addresstwo, setaddresstwo] = useState("")
  const [subject1, setsubject1] = useState("")
  const [mobile1, setmobile1] = useState("")
  const [email1, setemail1] = useState("")
  const [addressone1, setaddressone1] = useState("")
  const [addresstwo1, setaddresstwo1] = useState("")
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleClose2 = () => setShow2(false);
  const [FilterResult, setFilterResult] = useState([])
  const [schoolId, setSchoolId] = useState(""); // State to store school ID
  const [teachschoolId, setteachSchoolId] = useState(""); // State to store school ID
  const [teachschoolList, setteachSchoolList] = useState([]); // State to store school ID

  let navigate = useNavigate()


  const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
  var userinfo = JSON.parse(localStorage.getItem('userinfo'))
  // var studentteacherList = fetchacompanyReducer?.GetTeacherResponse?.results
  var studentteacherView = fetchacompanyReducer?.FetchTeacherResponse?.results[0]
  var SubjectList = fetchacompanyReducer?.GetSubjectResponse?.results
  var schoolList = fetchacompanyReducer?.FetchstudentResponse?.results[0]
  // var schoolID = fetchacompanyReducer?.FetchSchoolResponse?.results[0]


  console.log(fetchacompanyReducer?.FetchSchoolResponse)
  console.log(studentteacherView)

  const dispatch = useDispatch()

  useEffect(() => {
    let obj = {
      id: id
    }
    dispatch(FetchTeacherRequest(obj))
  }, [])

  // useEffect(() => {
  //   let obj = {
  //     id: id
  //   }
  //   dispatch(FetchSchoolRequest(obj))
  //   console.log("FetchSchoolRequest",obj)
  // }, [])

  useEffect(() => {
    let obj = { id: id };
    dispatch(FetchSchoolRequest(obj)); 
}, [dispatch, id]);

useEffect(() => {
    const schoolData = fetchacompanyReducer?.FetchSchoolResponse?.results;
    console.log('schoolData',schoolData);
    setteachSchoolId(schoolData?.id)
    if (schoolData && schoolData.length > 0) {
        setSchoolId(schoolData[0]?.id);
    }
}, [fetchacompanyReducer?.FetchSchoolResponse]);

console.log('schoolId',schoolId);

  useEffect(() => {
    let obj = {
      'name': '',
      'group_id': '',
    }
    dispatch(GetSubjectRequest(obj))
  }, [])

  
  const GetTeacher = async () => {
    try {
      const response = await ApiConnection.get(`student-teacher-crud/?server_type=production&id=${id}`)
      if (response?.status == 200) {
        setteachSchoolList(response?.data?.results)
      }
    } catch (e) { }
  }

  useEffect(() => {
    GetTeacher()
  }, [])

  useEffect(() => {
    var editinfo = fetchacompanyReducer?.FetchTeacherResponse?.results[0]
    console.log(editinfo)
    setteachername1(editinfo?.name)
    setsubject1(editinfo?.subject)
    setemail1(editinfo?.email)
    setmobile1(editinfo?.phone_no)
    setaddressone1(editinfo?.addressone)
    setaddresstwo1(editinfo?.addresstwo)

  }, [fetchacompanyReducer?.FetchTeacherResponse])


  const columns = [
    {
      name: 'Teacher Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Subject',
      selector: row => row.subject,
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
    },
    {
      name: 'Mobile No.',
      selector: row => row.phone_no,
      sortable: true,
    },
    {
      name: 'Address',
      selector: row => row.address1,
      sortable: true,
    },
    {
      name: 'Action',
      selector: (row) => (
        <>
          <NavLink onClick={() => handleShow2(row?.id, row?.school_id, row?.student_id)} className="mr-2"><i className="fa-solid fa-eye"></i></NavLink>
          <NavLink onClick={() => handleShow1(row?.id, row?.school_id, row?.student_id)}><i className="fa-solid fa-pen-to-square"></i></NavLink>
          <button onClick={() => DeleteHandle(row?.id)} className='table-btn'><i className="fa-solid fa-trash"></i></button>
        </>
      ),
    },
  ];

  useEffect(() => {
    const result = teachschoolList && teachschoolList.filter(data => {
      return data?.name.toLowerCase().match(search.toLowerCase())
    })
    setFilterResult(result)
  }, [search])

  useEffect(()=>{

    setFilterResult(teachschoolList)
  },[fetchacompanyReducer?.GetTeacherResponse])

  const handleShow1 = (tid, sid, jid) => {
    console.log("Edit Teacher", tid, sid, jid)
    seteditrowId(tid)
    setShow1(true)
    dispatch(FetchTeacherRequest({ rowId: tid, school_id: sid, student_id: jid }))
  };

  const handleShow2 = (tid, sid, jid) => {
    console.log(tid, sid, jid)
    setdetailsrowId(tid, sid, jid)
    setShow2(true)
    dispatch(FetchTeacherRequest({ rowId: tid, school_id: sid, student_id: jid }))
  };

  const handleShow = async () => {
    setShow(true);
    try {
      const response = await ApiConnection.get(`student-school-crud/?server_type=production&id=${schl_id}`)
      if (response?.status == 200) {
        console.log('response',response)
        // setstudentList(response?.data?.results)
      }
    } catch (e) { }
  }

  useEffect(() => {
    setShow1(false)

    dispatch(GetTeacherRequest())
  }, [fetchacompanyReducer?.UpdateTeacherResponse])

  useEffect(() => {
    dispatch(GetTeacherRequest())
  }, [fetchacompanyReducer?.DeleteTeacherResponse])

  const DeleteHandle = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeleteTeacherRequest(id))

        if (fetchacompanyReducer?.DeleteTeacherResponse) {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
        }

      }
    })
  }

  const AddHandle = () => {

    if (teachername == '') {
      Swal.fire({
        icon: "error",
        text: 'Start Date should be must',
      });
    } else {

      let obj = {
        "name": teachername,
        "subject": subject,
        "email": email,
        "mobile": mobile,
        "addressone": addressone,
        "addresstwo": addresstwo,
        // "start_date": moment(startdate).format("YYYY-MM-DD"),
      }

      dispatch(AddTeacherRequest({ obj: obj, school_id: schl_id, student_id: id, navigate: navigate }))
    }
  }

  const UpdateHandle = () => {

    if (teachername1 == '') {
      Swal.fire({
        icon: "error",
        text: 'Teacher Name should be must',
      });
    } else {

      let obj = {
        "name": teachername1,
        "subject": subject1,
        "email": email1,
        "mobile": mobile1,
        "addressone": addressone1,
        "addresstwo": addresstwo1
        // "start_date": moment(startdate).format("YYYY-MM-DD"),
      }
      dispatch(UpdateTeacherRequest({ obj: obj, school_id: id, student_id: id, rowId: editrowId }))
    }
  }

  return (

    <div className='module_list'>
      <Loader visible={fetchacompanyReducer.isloading} />
      <h5>Teacher</h5>

      <div className='row form-style mt-3'>
        <div className='col-lg-3'>
          <div className='form-group m-0'>
            <input type='text' className='form-control' placeholder='Teacher Name'
              value={search}
              onChange={(e) => setsearch(e.target.value)}
            />
          </div>
        </div>
        <div className='col-lg-3'>
          <button className='formBtn' onClick={handleShow}>Add Teacher</button>
          {/* <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/Training/AddTraining`}><i className="fa-solid fa-plus"></i> Add Training</NavLink> */}
        </div>
      </div>

      <div className='table-responsive'>

        <DataTable
          columns={columns}
          data={FilterResult}
          pagination
          highlightOnHover
        />
      </div>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header >
          <Modal.Title>Add Teacher</Modal.Title>
          <button onClick={handleClose} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
        </Modal.Header>
        <Modal.Body>
          <div className='row form-style mb-4'>

            {/* <div className='col-lg-6'>
              <label className='d-block text-right'>School Name:</label>
            </div>
            <div className='col-lg-6'>
              <b>{studentteacherView?.school_name}</b>
            </div> */}

            <div className='col-lg-12'>
              <div className='form-group'>
                <label className='d-block'>School Name :   </label>
                <b>{schoolList?.school_name}</b>
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Teacher Name <span class="asterisk">*</span></label>
                <input type='text' className='form-control' placeholder='Teacher Name'
                  value={teachername}
                  onChange={(e) => { setteachername(e.target.value) }}
                />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Subject Name </label>
                <input type='text' className='form-control' placeholder='Subject Name'
                  value={subject}
                  onChange={(e) => { setsubject(e.target.value) }}
                />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Email</label>
                <input type='text' className='form-control' placeholder='Email'
                  value={email}
                  onChange={(e) => { setemail(e.target.value) }}
                />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Mobile No.</label>
                <input type='text' className='form-control' placeholder='Mobile No.'
                  value={mobile}
                  onChange={(e) => { setmobile(e.target.value) }}
                  maxLength="10"
                  minLength="10"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Address 1</label>
                <input type='text' className='form-control' placeholder='Address 1'
                  value={addressone}
                  onChange={(e) => { setaddressone(e.target.value) }}
                />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Address 2</label>
                <input type='text' className='form-control' placeholder='Address 2'
                  value={addresstwo}
                  onChange={(e) => { setaddresstwo(e.target.value) }}
                />
              </div>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='formBtn' onClick={AddHandle}>Add</button>

        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1} size="lg">
        <Modal.Header >
          <Modal.Title>Edit Teacher</Modal.Title>
          <button onClick={handleClose1} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
        </Modal.Header>
        <Modal.Body>
          <div className='row form-style mb-4'>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Teacher Name <span class="asterisk">*</span></label>
                <input type='text' className='form-control' placeholder='Teacher Name'
                  value={teachername1}
                  onChange={(e) => { setteachername1(e.target.value) }}
                />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Subject Name </label>
                <input type='text' className='form-control' placeholder='Subject Name'
                  value={subject1}
                  onChange={(e) => { setsubject1(e.target.value) }}
                />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Email</label>
                <input type='text' className='form-control' placeholder='Email'
                  value={email1}
                  onChange={(e) => { setemail1(e.target.value) }}
                />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Mobile No.</label>
                <input type='text' className='form-control' placeholder='Mobile No.'
                  value={mobile1}
                  onChange={(e) => { setmobile1(e.target.value) }}
                  maxLength="10"
                  minLength="10"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Address 1</label>
                <input type='text' className='form-control' placeholder='Address 1'
                  value={addressone1}
                  onChange={(e) => { setaddressone1(e.target.value) }}
                />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Address 2</label>
                <input type='text' className='form-control' placeholder='Address 2'
                  value={addresstwo1}
                  onChange={(e) => { setaddresstwo1(e.target.value) }}
                />
              </div>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='formBtn' onClick={UpdateHandle}>Update</button>

        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2} size="lg">
        <Modal.Header >
          <Modal.Title>Teacher details</Modal.Title>
          <button onClick={handleClose2} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
        </Modal.Header>
        <Modal.Body>
          <div className='row form-style mb-4'>

            <div className='view-page'>

              <div className='row'>

                <div className='col-lg-6'>
                  <div className='form-group'>
                    <div className='row align-items-center'>
                      <div className='col-lg-6'>
                        <label className='d-block text-right'>Teacher Name :</label>
                      </div>
                      <div className='col-lg-6'>
                        <b>{studentteacherView?.name}</b>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-lg-6'>
                  <div className='form-group'>
                    <div className='row align-items-center'>
                      <div className='col-lg-6'>
                        <label className='d-block text-right'>Subject :</label>
                      </div>
                      <div className='col-lg-6'>
                        <b>{studentteacherView?.subject}</b>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-lg-6'>
                  <div className='form-group'>
                    <div className='row align-items-center'>
                      <div className='col-lg-6'>
                        <label className='d-block text-right'>Email :</label>
                      </div>
                      <div className='col-lg-6'>
                        <b>{studentteacherView?.email}</b>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-lg-6'>
                  <div className='form-group'>
                    <div className='row align-items-center'>
                      <div className='col-lg-6'>
                        <label className='d-block text-right'>Phone No. :</label>
                      </div>
                      <div className='col-lg-6'>
                        <b>{studentteacherView?.phone_no}</b>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-lg-6'>
                  <div className='form-group'>
                    <div className='row align-items-center'>
                      <div className='col-lg-6'>
                        <label className='d-block text-right'>Address 1 :</label>
                      </div>
                      <div className='col-lg-6'>
                        <b>{studentteacherView?.address1}</b>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-lg-6'>
                  <div className='form-group'>
                    <div className='row align-items-center'>
                      <div className='col-lg-6'>
                        <label className='d-block text-right'>Address 2 :</label>
                      </div>
                      <div className='col-lg-6'>
                        <b>{studentteacherView?.address2}</b>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default StudentTeacher