import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, NavLink, useParams } from 'react-router-dom'
import { AddquesRequest, GetGradeRequest, GetModuleTypeRequest, GetModuleRequest, FetchtestRequest, GettestRequest, GetSubjectRequest, GetTopicRequest, GetTopicChangeRequest, GetModuleChangeRequest, GetSyllabusChangeRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'react-time-picker';
import moment from "moment";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Accordion from 'react-bootstrap/Accordion';


const AddTest = () => {

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var GradeList = fetchacompanyReducer?.GetGradeResponse?.results
    var SubjectList = fetchacompanyReducer?.GetSubjectResponse?.results
    // var testList = fetchacompanyReducer?.GettestResponse?.results[0].test_type
    var testdata = fetchacompanyReducer?.GettestResponse?.results[0]
    var moduleList = fetchacompanyReducer?.GetModuleChangeResponse?.results
    var topicList = fetchacompanyReducer?.GetTopicChangeResponse?.results
    var SyllabusList = fetchacompanyReducer?.GetSyllabusChangeResponse?.results

    console.log(SyllabusList)
    console.log(testdata?.test_type)
    // console.log(fetchacompanyReducer?.FetchtestResponse?.results[0].test_type)

    useEffect(() => {
        let obj = {
            'name': '',
            'group_id': '',
        }

        let obj2 = {
            'name': '',
            'group_id': ''
        }

        let obj3 = {
            'name': '',
            'module_id': '',
            'group_id': '',
            'syllabus_id': ''
        }

        dispatch(GetGradeRequest())
        dispatch(GetSyllabusChangeRequest())
        dispatch(GetSubjectRequest(obj))
        dispatch(GetModuleRequest(obj2))
        dispatch(GetTopicRequest(obj3));
    }, [])

    const editorConfiguration = {
        toolbar: ['bold', 'italic', 'heading', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'highlight', 'fontSize', 'blockQuote', '|', 'bulletedList', 'numberedList', '|', 'imageUpload', 'insertTable', 'mediaEmbed', 'undo', 'redo']
    };

    const { id } = useParams()
    const dispatch = useDispatch()
    let navigate = useNavigate()

    const [grade, setgrade] = useState("")
    const [subject, setsubject] = useState("")
    const [syllabus, setsyllabus] = useState("")
    const [test, settest] = useState("")
    const [totalques, settotalques] = useState("")
    const [existing, setisexisting] = useState("")
    const [correctanswer, setisanswer] = useState("")
    const [totalquesno, settotalquesno] = useState("")
    const [clickCount, setClickCount] = useState(0);
    const [traininfo, settraininfo] = useState([{
        modulename: '',
        topicname: '',
        question: '',
        ques_img: '',
        ques_hint: '',
        ques_marks: '',
        answer_a: '',
        answer_a_img: '',
        answer_b: '',
        answer_b_img: '',
        answer_c: '',
        answer_c_img: '',
        answer_d: '',
        answer_d_img: '',
        correctanswer: '',
        isdisabled: false
    }]);
    console.log(totalquesno)

    const ref = React.useRef(null);
    const [src, setSrc] = useState('');
    const onChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                setSrc(e.target.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    const EditorHandle = (value, key, index) => {
        var newValue = [...traininfo];
        newValue[index][key] = value.getData()
        settraininfo(newValue);
    }

    const ModuleHandle = (value, key, index) => {
        var newValue = [...traininfo];
        newValue[index][key] = value
        settraininfo(newValue);

        dispatch(GetTopicChangeRequest(value))
    }

    const SubjectHandle = (e) => {
        setsubject(e.target.value)
        let obj = {
            id: e.target.value
        }
        // dispatch(GetModuleChangeRequest(obj))
        dispatch(GetSyllabusChangeRequest(obj))
    }

    const SyllabusHandle = (e) => {
        console.log('GetModuleChangeRequest',e,syllabus)
        setsyllabus(e.target.value)
        let obj = {
            id: e.target.value
        }
        dispatch(GetModuleChangeRequest(obj))
    }

    useEffect(() => {
        let obj = {
            id: id
        }
        dispatch(FetchtestRequest(obj))

    }, [])

    // useEffect(() => {

    //     var editdata = fetchacompanyReducer?.GettestResponse?.results[0]
    //     console.log(editdata)
    //     setgrade(editdata?.grade_id)
    //     setsubject(editdata?.subject_id)
    //     setsyllabus(editdata?.syllabus_id)
    // }, [fetchacompanyReducer?.GettestResponse])

    useEffect(() => {
        var editdata = fetchacompanyReducer.GettestResponse?.results?.[0]
        setgrade(editdata?.grade_id)
        setsubject(editdata?.subject_id)
        settotalquesno(editdata?.total_question)
        // setmodulename(editdata?.module_id)
        // settopicname(editdata?.topic_id)
        setsyllabus(editdata?.syllabus_id)

        let obj = {
            id: editdata?.subject_id
        }
        dispatch(GetSyllabusChangeRequest(obj))

        let objone = {
            id: editdata?.group_id
        }
        dispatch(GetModuleChangeRequest(objone))

        dispatch(GetTopicChangeRequest(editdata?.module_id))

    }, [fetchacompanyReducer?.GettestResponse && fetchacompanyReducer?.FetchtestResponse])


    const SubmitHandle = () => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

        if (grade == '') {
            Swal.fire({
                icon: "error",
                text: 'Grade should be must',
            });
            return;
        }

            // Check if test type is not 'group'
    if (testdata?.test_type !== 'group') {
        // Validate module and topic fields
        for (let i = 0; i < traininfo.length; i++) {
            if (!traininfo[i].modulename) {
                Swal.fire({
                    icon: "error",
                    text: 'Module is required for all questions.',
                });
                return;
            }
            if (!traininfo[i].topicname) {
                Swal.fire({
                    icon: "error",
                    text: 'Topic is required for all questions.',
                });
                return;
            }
        }
    }

    // Validate question fields
    for (let i = 0; i < traininfo.length; i++) {
        if (!traininfo[i].question) {
            Swal.fire({
                icon: "error",
                text: `Question is required for question ${i + 1}.`,
            });
            return;
        }
        if (!traininfo[i].ques_marks) {
            Swal.fire({
                icon: "error",
                text: `Question Marks are required for question ${i + 1}.`,
            });
            return;
        }
        if (!traininfo[i].answer_a || !traininfo[i].answer_b || !traininfo[i].answer_c || !traininfo[i].answer_d) {
            Swal.fire({
                icon: "error",
                text: `All options are required for question ${i + 1}.`,
            });
            return;
        }
        if (!traininfo[i].correctanswer) {
            Swal.fire({
                icon: "error",
                text: `Correct answer is required for question ${i + 1}.`,
            });
            return;
        }
    }


            var arr = []

            traininfo.forEach((element, index) => {
                arr.push({
                    "module_id": Number(element.modulename),
                    "topic_id": Number(element.topicname),
                    "question": element.question,
                    "question_image": element.ques_img,
                    "question_hints": element.ques_hint,
                    "question_marks": Number(element.ques_marks),
                    "option_1": element.answer_a,
                    "option_1_image": element.answer_a_img,
                    "option_2": element.answer_b,
                    "option_2_image": element.answer_b_img,
                    "option_3": element.answer_c,
                    "option_3_image": element.answer_c_img,
                    "option_4": element.answer_d,
                    "option_4_image": element.answer_d_img,
                    "answer": Number(element.correctanswer),
                })
            })

            console.log(arr)
            let obj = {

                'grade_id': grade,
                'subject_id': subject,
                'syllabus_id': syllabus,
                'question_data_list': arr[0].training_id !== '' ? arr : [],
            }

            dispatch(AddquesRequest({ obj: obj, id: id, navigate: navigate }))
    }

    const AddHandle = () => {
        // if (clickCount >= parseInt(totalquesno) - 1) {
        //     Swal.fire({
        //         icon: "error",
        //         text: ("You can't add more than " + parseInt(totalquesno) + " questions."),
        //     });
        //     // alert("You can't add more than " + totalquesno + " questions.");
        //     return;
        //   }
        const rawinput = {
            modulename: '',
            topicname: '',
            question: '',
            ques_img: '',
            ques_hint: '',
            questionnumber: '',
            ques_type: '',
            ques_marks: '',
            answer_a: '',
            answer_a_img: '',
            answer_b: '',
            answer_b_img: '',
            answer_c: '',
            answer_c_img: '',
            answer_d: '',
            answer_d_img: '',
            correctanswer: '',
            isdisabled: false
        }

        settraininfo([...traininfo, rawinput]);
        setClickCount(clickCount + 1);
    }

    const QuestionHandle = (value, key, index, _is) => {
        var newValue = [...traininfo];
        newValue[index][key] = value
        settraininfo(newValue);
    }

    const DeleteHandle = (index) => {
        var newValue = [...traininfo];
        newValue.splice(index, 1);
        settraininfo(newValue);
    }

    const radioHandle = (e) => {
        setisexisting(e.target.value === 'true');
    }

    useEffect(() => {
        dispatch(GetModuleTypeRequest())
    }, [])

    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h5>Add Questions</h5>
                </div>
                <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-50px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>
                <div className='row form-style mb-4'>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Select Grade <span class="asterisk">*</span></label>
                            <select className='form-control' value={grade} disabled
                                onChange={(e) => setgrade(e.target.value)}>
                                <option value="">--Grade--</option>
                                {GradeList && GradeList.map((item, i) => {
                                    return (
                                        <option key={i} value={item?.id} >{item?.grade_name}</option>
                                    )

                                })}
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Select Subject <span class="asterisk">*</span></label>
                            <select className='form-control' value={subject} disabled
                                onChange={SubjectHandle}>
                                <option value="">--subject--</option>
                                {SubjectList && SubjectList.map((item, i) => {
                                    return (
                                        <option key={i} value={item?.id} >{item?.subject_name}</option>
                                    )

                                })}
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Select Syllabus <span class="asterisk">*</span></label>
                            <select className='form-control' value={syllabus} disabled
                                onChange={SyllabusHandle}>
                                <option value="">--syllabus--</option>
                                {SyllabusList && SyllabusList.map((item, i) => {
                                    return (
                                        <option key={i} value={item?.id} >{item?.name}</option>
                                    )

                                })}
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-12 mb-2'>
                        {/* <div className='form-group' style={{ marginBottom: '-30px' }}>
                            <input type='radio' name="existing" id="cp" value="true" checked={existing === true} onChange={radioHandle} />  <span for="cp">Add New Question</span> &nbsp;
                            <input type='radio' name="existing" id="tp" value="false" checked={existing === false} onChange={radioHandle} />  <span for="tp">Existing Question</span>
                        </div> */}
                        <button className='btn btn-primary' style={{ float: 'right' }} onClick={AddHandle}><i className="fa-solid fa-plus"></i> Add More Questions</button>
                    </div>


                    <div className='col-lg-12'>
                        <Accordion defaultActiveKey="0">
                            {traininfo.map((item, index) => {
                                return (

                                    <div className='testrow question-accordian'>
                                        <Accordion.Item eventKey={index.toString()}>
                                            <Accordion.Header>Sl No #{index + 1}</Accordion.Header>
                                            <Accordion.Body>

                                                <div className='row' key={index}>
                                                    {testdata?.test_type != 'group' &&
                                                        <div className='col-lg-5'>
                                                            <div className='form-group'>
                                                                <label className='selectlabel'>Select Module <span class="asterisk">*</span> </label>
                                                                <select className='form-control' value={item.modulename}
                                                                    onChange={(e) => ModuleHandle(e.target.value, 'modulename', index)}>
                                                                    <option value="">--Module---</option>
                                                                    {moduleList && moduleList.map((item, i) => {
                                                                        return (
                                                                            <option key={i} value={item?.id} >{item?.name}</option>
                                                                        )

                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    }

                                                    {testdata?.test_type != 'group' &&
                                                        <div className='col-lg-5' >
                                                            <div className='form-group'>
                                                                <label className='selectlabel'>Select Topic <span class="asterisk">*</span> </label>
                                                                <select className='form-control' value={item.topicname}
                                                                    onChange={(e) => QuestionHandle(e.target.value, 'topicname', index)}>
                                                                    <option value="">--Topic---</option>

                                                                    {topicList && topicList.map((item, i) => {
                                                                        return (
                                                                            <option key={i} value={item?.id} >{item?.name}</option>
                                                                        )

                                                                    })}

                                                                </select>
                                                            </div>
                                                        </div>
                                                    }

                                                    <div className='col-lg-10'>
                                                        <div className='form-group'>
                                                            <label className='selectlabel'>Enter Question<span class="asterisk">*</span></label>
                                                            <CKEditor
                                                                editor={Editor}
                                                                config={editorConfiguration}
                                                                value={item.question}
                                                                data=""
                                                                onChange={(event, editor) => EditorHandle(editor, 'question', index)}
                                                            />
                                                        </div>
                                                    </div>


                                                    <div className='col-lg-5'>
                                                        <div className='form-group'>
                                                            <label className='selectlabel'>Enter Question Hint</label>
                                                            <input type="text" className="form-control"
                                                                placeholder="Question Hint"
                                                                style={{ height: '50px', padding: '11px 30px' }}
                                                                value={item.ques_hint}
                                                                onChange={(e) => QuestionHandle(e.target.value, 'ques_hint', index)} />

                                                        </div>
                                                    </div>

                                                    <div className='col-lg-5'>
                                                        <div className='form-group'>
                                                            <label className='selectlabel'>Enter Question Marks <span class="asterisk">*</span></label>
                                                            <input type="text" className="form-control"
                                                                placeholder="Question Marks"
                                                                style={{ height: '50px', padding: '11px 30px' }}
                                                                value={item.ques_marks}
                                                                onChange={(e) => QuestionHandle(e.target.value, 'ques_marks', index)}
                                                                onKeyPress={(event) => {
                                                                    if (!/[0-9]/.test(event.key)) {
                                                                      event.preventDefault();
                                                                    }
                                                                  }}
                                                                />
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-5'>
                                                        <div className='form-group'>
                                                            <label className='selectlabel'>Enter Option 1 <span class="asterisk">*</span></label>
                                                            <textarea
                                                                className="form-control"
                                                                placeholder="Option 1"
                                                                style={{ height: '50px', padding: '11px 30px' }}
                                                                value={item.answer_a}
                                                                onChange={(e) => QuestionHandle(e.target.value, 'answer_a', index)}
                                                            ></textarea>
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-5'>
                                                        <div className='form-group'>
                                                            <label className='selectlabel'>Enter Option 2 <span class="asterisk">*</span></label>
                                                            <textarea
                                                                className="form-control"
                                                                placeholder="Option 2"
                                                                style={{ height: '50px', padding: '11px 30px' }}
                                                                value={item.answer_b}
                                                                onChange={(e) => QuestionHandle(e.target.value, 'answer_b', index)}
                                                            ></textarea>
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-5'>
                                                        <div className='form-group'>
                                                            <label className='selectlabel'>Enter Option 3 <span class="asterisk">*</span></label>
                                                            <textarea
                                                                className="form-control"
                                                                placeholder="Option 3"
                                                                style={{ height: '50px', padding: '11px 30px' }}
                                                                value={item.answer_c}
                                                                onChange={(e) => QuestionHandle(e.target.value, 'answer_c', index)}
                                                            ></textarea>
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-5'>
                                                        <div className='form-group'>
                                                            <label className='selectlabel'>Enter Option 4 <span class="asterisk">*</span></label>
                                                            <textarea
                                                                className="form-control"
                                                                placeholder="Option 4"
                                                                style={{ height: '50px', padding: '11px 30px' }}
                                                                value={item.answer_d}
                                                                onChange={(e) => QuestionHandle(e.target.value, 'answer_d', index)}
                                                            ></textarea>
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-10'>
                                                        <div className='form-group'>
                                                            <label className='selectlabel'>Select Correct Answer <span class="asterisk">*</span></label>
                                                            <select className='form-control' value={item.correctanswer}
                                                                onChange={(e) => QuestionHandle(e.target.value, 'correctanswer', index)}>
                                                                <option value="">--Correct Answer---</option>
                                                                <option value="1">Option 1</option>
                                                                <option value="2">Option 2</option>
                                                                <option value="3">Option 3</option>
                                                                <option value="4">Option 4</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-1'>
                                                        {index > 0 &&
                                                            <div className='form-group'>
                                                                <button className='btn brn-sm btn-danger' onClick={() => DeleteHandle(index)}><i className="fa-solid fa-trash-can"></i></button>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </div>

                                )
                            })}

                        </Accordion>


                    </div>


                </div>
                <button className='formBtn' onClick={SubmitHandle}>Save</button>

            </div>
        </div>
    )
}

export default AddTest