import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { AddTitleRequest, GetTitleRequest } from '../../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../../utils/Loader'
import Swal from "sweetalert2";
import ReactSwitch from 'react-switch';
import Accordion from 'react-bootstrap/Accordion';
import ApiConnection from '../../../utils/ApiConnection'


const AddRolePermission = () => {
  const [tname, settname] = useState("")
  const [checked, setChecked] = useState({});
  const [usertype, setusertype] = useState("");
  const [title, settitle] = useState("");

  const [Sidemenus, setSidemenus] = useState([])

  const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
  var userinfo = JSON.parse(localStorage.getItem('userinfo'))
  var TitleList = fetchacompanyReducer?.GetTitleResponse?.results

  const dispatch = useDispatch()
  let navigate = useNavigate()

  // const Sidemenus = [
  //     {
  //         id: "1",
  //         menuname: "Home",
  //         Permissiondetails: {
  //             view: 1,
  //             add: 1,
  //             edit: 0,
  //             delete: 1,
  //             export: 1,
  //         }
  //     },
  //     {
  //         id: "2",
  //         menuname: "Employees",
  //         empmenu: [
  //             {
  //                 id: "28",
  //                 empname: "Location",
  //             },
  //             {
  //                 id: "29",
  //                 empname: "Virtual Location",
  //             },
  //             {
  //                 id: "30",
  //                 empname: "Training",
  //             },
  //             {
  //                 id: "31",
  //                 empname: "Class/Subject",
  //             },
  //             {
  //                 id: "32",
  //                 empname: "Attendance",
  //             },
  //             {
  //                 id: "33",
  //                 empname: "Document",
  //             },
  //             {
  //                 id: "34",
  //                 empname: "Activities",
  //             },
  //             {
  //                 id: "35",
  //                 empname: "Schedule",
  //             }
  //         ]
  //     },
  //     {
  //         id: "3",
  //         menuname: "Prospects",
  //         empmenu: [
  //             {
  //                 id: "44",
  //                 empname: "Guardians",
  //             },
  //             {
  //                 id: "45",
  //                 empname: "Students",
  //             }
  //         ]
  //     },
  //     {
  //         id: "4",
  //         menuname: "Customers",
  //     },
  //     {
  //         id: "5",
  //         menuname: "Student",
  //         empmenu: [
  //             {
  //                 id: "36",
  //                 empname: "Assessments",
  //             },
  //             {
  //                 id: "37",
  //                 empname: "Subscriptions",
  //             },
  //             {
  //                 id: "38",
  //                 empname: "Attendance",
  //             },
  //             {
  //                 id: "39",
  //                 empname: "Rewards",
  //             },
  //             {
  //                 id: "40",
  //                 empname: "Documents",
  //             },
  //             {
  //                 id: "41",
  //                 empname: "Activities",
  //             },
  //             {
  //                 id: "42",
  //                 empname: "Teacher",
  //             },
  //             {
  //                 id: "43",
  //                 empname: "Schedule",
  //             }
  //         ]
  //     },
  //     {
  //         id: "6",
  //         menuname: "Curriculam",
  //         submenu: [
  //             {
  //                 id: "17",
  //                 name: "Group",
  //             },
  //             {
  //                 id: "18",
  //                 name: "Grade",
  //             },
  //             {
  //                 id: "19",
  //                 name: "Subject",
  //             },
  //             {
  //                 id: "20",
  //                 name: "Syllabus",
  //                 childmenu: [
  //                     {
  //                         id: "26",
  //                         name: "Module",
  //                     },
  //                     {
  //                         id: "27",
  //                         name: "Topic",
  //                     },
  //                 ]
  //             }
  //         ]
  //     },
  //     {
  //         id: "7",
  //         menuname: "Service & Pricing",
  //         empmenu: [
  //             {
  //                 id: "46",
  //                 empname: "Service & Pricing Setup",
  //             },
  //             {
  //                 id: "47",
  //                 empname: "Purchase Registration",
  //             },
  //             {
  //                 id: "48",
  //                 empname: "Membership Setup",
  //             }
  //         ]
  //     },
  //     {
  //         id: "8",
  //         menuname: "Payment",
  //     },
  //     {
  //         id: "9",
  //         menuname: "Tasks",
  //     },
  //     {
  //         id: "10",
  //         menuname: "Documents",
  //     },
  //     {
  //         id: "11",
  //         menuname: "Schedule",
  //     },
  //     {
  //         id: "12",
  //         menuname: "Reports",
  //     },
  //     {
  //         id: "13",
  //         menuname: "Locations",
  //     },
  //     {
  //         id: "14",
  //         menuname: "Settings",
  //         submenu: [
  //             {
  //                 id: "21",
  //                 name: "Title",
  //             },
  //             {
  //                 id: "22",
  //                 name: "Background Check...",
  //             },
  //             {
  //                 id: "23",
  //                 name: "Module Type",
  //             },
  //             {
  //                 id: "24",
  //                 name: "Profile",
  //             },
  //             {
  //                 id: "25",
  //                 name: "Email Templ...",
  //             },
  //         ]
  //     },
  //     {
  //         id: "15",
  //         menuname: "Training",
  //     },
  //     {
  //         id: "16",
  //         menuname: "Assessment",
  //     },
  // ]

  useEffect(() => {
    if (usertype === 'EMPLOYEE') {
      dispatch(GetTitleRequest());
    }
    if (usertype && (usertype !== 'EMPLOYEE' || (usertype === 'EMPLOYEE' && title))) {
      GetTermsofServices(usertype, title);
    } else {
      setSidemenus([]);
    }
  }, [usertype]);

  useEffect(() => {
    if (usertype === 'EMPLOYEE' && title) {
      GetTermsofServices(usertype, title);
    }
  }, [title]);
  
  const GetTermsofServices = async (usertype, title = '') => {
    try {
      const userTypeParam = usertype === 'EMPLOYEE' ? `user_type=${title}` : `user_type=${usertype}`;      const response = await ApiConnection.get(`users/menu-submenu-tabmenu-crud/api/?server_type=production&${userTypeParam}`);
      if (response?.status === 200) {
        setSidemenus(response?.data?.results);
        const initialChecked = {};
        response?.data?.results.forEach(item => {
          initialChecked[item.id] = ensureAllPermissions(item.menu_user_permission_list);
  
          item.tab_menu_list.forEach(tab => {
            if (!initialChecked[item.id]) initialChecked[item.id] = {};
            initialChecked[item.id][tab.tab_menu_id] = ensureAllPermissions(tab.tab_menu_user_permission_list);
          });
  
          item.sub_menu_list.forEach(sub => {
            if (!initialChecked[item.id]) initialChecked[item.id] = {};
            initialChecked[item.id][sub.sub_menu_id] = ensureAllPermissions(sub.sub_menu_user_permission_list);
  
            sub.sub_tab_menu_list.forEach(subTab => {
              if (!initialChecked[item.id][sub.sub_menu_id]) initialChecked[item.id][sub.sub_menu_id] = {};
              initialChecked[item.id][sub.sub_menu_id][subTab.tab_menu_id] = ensureAllPermissions(subTab.sub_tab_menu_user_permission_list);
            });
          });
        });
        setChecked(initialChecked);
      }
    } catch (error) {
    }
  };

  const handleChange = (event, index, subIndex, tabIndex, subTabIndex, key) => {
    const isChecked = event.target.checked ? 1 : 0;
    setChecked(prevChecked => {
      const newChecked = { ...prevChecked };
      if (subTabIndex !== undefined) {
        if (!newChecked[index]) newChecked[index] = {};
        if (!newChecked[index][subIndex]) newChecked[index][subIndex] = {};
        if (!newChecked[index][subIndex][tabIndex]) newChecked[index][subIndex][tabIndex] = {};
        newChecked[index][subIndex][tabIndex][subTabIndex] = {
          ...newChecked[index][subIndex][tabIndex][subTabIndex],
          [key]: isChecked
        };
      } else if (tabIndex !== undefined) {
        if (!newChecked[index]) newChecked[index] = {};
        if (!newChecked[index][subIndex]) newChecked[index][subIndex] = {};
        newChecked[index][subIndex][tabIndex] = {
          ...newChecked[index][subIndex][tabIndex],
          [key]: isChecked
        };
      } else if (subIndex !== undefined) {
        if (!newChecked[index]) newChecked[index] = {};
        newChecked[index][subIndex] = {
          ...newChecked[index][subIndex],
          [key]: isChecked
        };
      }  else if (subTabIndex !== undefined) {
        if (!newChecked[index]) newChecked[index] = {};
        newChecked[index][subTabIndex] = {
          ...newChecked[index][subTabIndex],
          [key]: isChecked
        };
      } else {
        newChecked[index] = {
          ...newChecked[index],
          [key]: isChecked
        };
      }
      return newChecked;
    });
  };

  const convertBooleanToNumber = (obj) => {
    const newObj = {};
    for (const key in obj) {
      newObj[key] = obj[key] ? 1 : 0;
    }
    return newObj;
  };

  const ensureAllPermissions = (permissions) => {
    const allPermissions = {
      is_add: 0,
      is_view: 0,
      is_export: 0,
      is_delete: 0,
      is_edit: 0,
    };

    for (const key in permissions) {
      if (permissions.hasOwnProperty(key)) {
        allPermissions[key] = permissions[key];
      }
    }

    return convertBooleanToNumber(allPermissions);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (usertype === 'EMPLOYEE' && !title) {
      alert('Please select a title for Employee.');
      return;
    }

    const dataToSend = Sidemenus.map(item => ({
      ...item,
      menu_user_permission_list: {
        ...item.menu_user_permission_list,
        is_add: checked[item.id]?.is_add ? 1 : 0,
        is_view: checked[item.id]?.is_view ? 1 : 0,
        is_export: checked[item.id]?.is_export ? 1 : 0,
        is_delete: checked[item.id]?.is_delete ? 1 : 0,
        is_edit: checked[item.id]?.is_edit ? 1 : 0,
      },
      tab_menu_list: item.tab_menu_list.map(tab => ({
        ...tab,
        tab_menu_user_permission_list: {
          ...tab.tab_menu_user_permission_list,
          is_add: checked[item.id]?.[tab.tab_menu_id]?.is_add ? 1 : 0,
          is_view: checked[item.id]?.[tab.tab_menu_id]?.is_view ? 1 : 0,
          is_export: checked[item.id]?.[tab.tab_menu_id]?.is_export ? 1 : 0,
          is_delete: checked[item.id]?.[tab.tab_menu_id]?.is_delete ? 1 : 0,
          is_edit: checked[item.id]?.[tab.tab_menu_id]?.is_edit ? 1 : 0,
        },
      })),
      sub_menu_list: item.sub_menu_list.map(sub => ({
        ...sub,
        sub_menu_user_permission_list: {
          ...sub.sub_menu_user_permission_list,
          is_add: checked[item.id]?.[sub.sub_menu_id]?.is_add ? 1 : 0,
          is_view: checked[item.id]?.[sub.sub_menu_id]?.is_view ? 1 : 0,
          is_export: checked[item.id]?.[sub.sub_menu_id]?.is_export ? 1 : 0,
          is_delete: checked[item.id]?.[sub.sub_menu_id]?.is_delete ? 1 : 0,
          is_edit: checked[item.id]?.[sub.sub_menu_id]?.is_edit ? 1 : 0,
        },
        sub_tab_menu_list: sub.sub_tab_menu_list.map(subTab => ({
          ...subTab,
          sub_tab_menu_user_permission_list: {
            ...subTab.sub_tab_menu_user_permission_list,
            is_add: checked[item.id]?.[sub.sub_menu_id]?.[subTab.tab_menu_id]?.is_add ? 1 : 0,
            is_view: checked[item.id]?.[sub.sub_menu_id]?.[subTab.tab_menu_id]?.is_view ? 1 : 0,
            is_export: checked[item.id]?.[sub.sub_menu_id]?.[subTab.tab_menu_id]?.is_export ? 1 : 0,
            is_delete: checked[item.id]?.[sub.sub_menu_id]?.[subTab.tab_menu_id]?.is_delete ? 1 : 0,
            is_edit: checked[item.id]?.[sub.sub_menu_id]?.[subTab.tab_menu_id]?.is_edit ? 1 : 0,
          },
        })),
      })),
    }));

    let obj = {
      user_type: usertype === 'EMPLOYEE' ? title : usertype,
      results: dataToSend,
    };

    try {
      const response = await ApiConnection.post(`/users/role-permission-crud/api/?server_type=production`, obj);
      if (response?.status === 200) {
        Swal.fire({
          icon: "success",
          text: response?.data?.msg,
      });
        GetTermsofServices(usertype, title);
      }
    } catch (error) {
    }
  };


  return (
    <div className='common-layout'>
      <Loader visible={fetchacompanyReducer.isloading} />
      <div className='innerlayout p-3'>
        <div className='mb-4'>
          <h3> Role Permission Management </h3>
        </div>
        <div className='button-part mb-4' style={{ marginLeft: '907px', marginTop: '-67px' }}>
          <ul>
            <li>
              <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
            </li>
          </ul>
        </div>
        <div className='row form-style mb-1'>
          <div className='col-lg-12'>
            <div className='form-group'>
              {/* <form onSubmit={handleSubmit}> */}
              <div className='col-lg-12 d-flex'>
              <div className='col-lg-3 main-div d-flex'>
                <div className='form-group'>
                  <label className='selectlabel' style={{ fontWeight: '500' }}>Usertype <span class="asterisk">*</span></label>
                  <select className='form-control' value={usertype}
                    onChange={(e) => { setusertype(e.target.value) }}>
                    <option value="" disabled>--Select User Type---</option>
                    <option value="COMPANY">Company</option>
                    <option value="EMPLOYEE">Employee</option>
                    <option value="STUDENT">Student</option>
                    <option value="PROSPECT">Prospect</option>
                    <option value="GUARDIAN">Guardian</option>
                  </select>
                </div>
              </div>

              {usertype === 'EMPLOYEE' && (
              <div className='col-lg-3 main-div ml-3'>
                <div className='form-group'>
                  <label className='selectlabel' style={{ fontWeight: '500' }}>Title <span class="asterisk">*</span></label>
                  <select className='form-control' value={title}
                    onChange={(e) => { settitle(e.target.value) }}>
                    <option value="" disabled>--Select Title---</option>
                    {TitleList && TitleList.map((title, i) => {
                        return (
                          <option key={i} value={title?.name}>{title?.name}</option>
                        )
                    })}
                  </select>
                </div>
              </div>
              )}
              </div>

              <Accordion defaultActiveKey="0" className='view-accordian'>
                {Sidemenus.map((item, index) => (
                  <Accordion.Item eventKey={index.toString()} id={item?.id.toString()} key={item?.id}>
                    <Accordion.Header style={{ backgroundColor: '#abe2fa', padding: '5px 10px 1px 10px', display: 'flex', alignItems: 'center' }} className='mt-2'>
                      <i className="fas fa-chalkboard-teacher"></i>
                      <span className='ml-2'>{item?.menu_name}</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div key={item?.id} className='menu-item'>
                        <div className='checkbox-row d-flex'>
                          {Object.entries(item?.menu_user_permission_list).map(([permission, value]) => (
                            <div className='col-lg-1' key={permission}>
                              <div className='form-group d-flex align-item-center'>
                                <input
                                  type='checkbox'
                                  name={`role_${index}_${permission}`}
                                  checked={checked[item.id]?.[permission] === 1}
                                  onChange={(e) => handleChange(e, item.id, undefined, undefined, undefined, permission)} />
                                <label className='selectlabel ml-2 mt-1'>
                                  {permission.charAt(3).toUpperCase() + permission.slice(4)}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      {checked[item.id]?.is_view && (
                        <>
                          {item.tab_menu_list.length > 0 && (
                            <Accordion defaultActiveKey="0" className='view-accordian' style={{ width: '90%', marginLeft: '90px', marginTop: '-20px', marginBottom: '25px' }}>
                              {item.tab_menu_list.map((tab, tabIndex) => (
                                <Accordion.Item
                                  eventKey={`${index}-${tabIndex}`}
                                  id={tab.tab_menu_id.toString()}
                                  key={tab.tab_menu_id}
                                >
                                  <Accordion.Header style={{ backgroundColor: 'rgb(248 250 171)', padding: '5px 10px 1px 10px', display: 'flex', alignItems: 'center' }} className='mt-2'>
                                    <i className="fas fa-chalkboard-teacher"></i>
                                    <span className='ml-2'>{tab?.tab_menu_name}</span>
                                  </Accordion.Header>
                                  <Accordion.Body style={{ marginBottom: '-20px' }}>
                                    <div key={tab?.tab_menu_id} className='menu-item'>
                                      <div className='checkbox-row d-flex'>
                                        {Object.entries(tab?.tab_menu_user_permission_list).map(([permission, value]) => (
                                          <div className='col-lg-1' key={permission}>
                                            <div className='form-group d-flex align-item-center'>
                                              <input
                                                type='checkbox'
                                                name={`role_${index}_${tabIndex}_${permission}`}
                                                checked={checked[item.id]?.[tab.tab_menu_id]?.[permission] === 1}
                                                onChange={(e) => handleChange(e, item.id, tab.tab_menu_id, undefined, undefined, permission)} />
                                              <label className='selectlabel ml-2 mt-1'>{permission.charAt(3).toUpperCase() + permission.slice(4)}</label>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                              ))}
                            </Accordion>
                          )}
                          {item.sub_menu_list.length > 0 && (
                            <Accordion defaultActiveKey="0" className='view-accordian' style={{ width: '90%', marginLeft: '90px', marginTop: '-20px', marginBottom: '25px' }}>
                              {item.sub_menu_list.map((sub, subIndex) => (
                                <Accordion.Item
                                  eventKey={`${index}-${subIndex}`}
                                  id={sub.sub_menu_id.toString()}
                                  key={sub.sub_menu_id}
                                >
                                  <Accordion.Header style={{ backgroundColor: 'rgb(248 250 171)', padding: '5px 10px 1px 10px', display: 'flex', alignItems: 'center' }} className='mt-2'>
                                    <i className="fas fa-chalkboard-teacher"></i>
                                    <span className='ml-2'>{sub?.sub_menu_name}</span>
                                  </Accordion.Header>
                                  <Accordion.Body style={{ marginBottom: '-20px' }}>
                                    <div key={sub?.sub_menu_id} className='menu-item'>
                                      <div className='checkbox-row d-flex'>
                                        {Object.entries(sub?.sub_menu_user_permission_list).map(([permission, value]) => (
                                          <div className='col-lg-1' key={permission}>
                                            <div className='form-group d-flex align-item-center'>
                                              <input
                                                type='checkbox'
                                                name={`role_${index}_${subIndex}_${permission}`}
                                                checked={checked[item.id]?.[sub.sub_menu_id]?.[permission] === 1}
                                                onChange={(e) => handleChange(e, item.id, sub.sub_menu_id, undefined, undefined, permission)} />
                                              <label className='selectlabel ml-2 mt-1'>{permission.charAt(3).toUpperCase() + permission.slice(4)}</label>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                    {sub.sub_tab_menu_list.length > 0 && (
                                      <Accordion defaultActiveKey="0" className='view-accordian' style={{ width: '90%', marginLeft: '90px', marginTop: '-20px', marginBottom: '25px' }}>
                                        {sub.sub_tab_menu_list.map((subTab, subTabIndex) => (
                                          <Accordion.Item
                                            eventKey={`${index}-${subIndex}-${subTabIndex}`}
                                            id={subTab?.tab_menu_id.toString()}
                                            key={subTab?.tab_menu_id}
                                          >
                                            <Accordion.Header style={{ backgroundColor: 'rgb(255 227 202)', padding: '5px 10px 1px 10px', display: 'flex', alignItems: 'center' }} className='mt-2'>
                                              <i className="fas fa-chalkboard-teacher"></i>
                                              <span className='ml-2'>{subTab?.tab_menu_name}</span>
                                            </Accordion.Header>
                                            <Accordion.Body style={{ marginBottom: '-20px' }}>
                                              <div key={subTab?.tab_menu_id} className='menu-item'>
                                                <div className='checkbox-row d-flex'>
                                                  {Object.entries(subTab?.sub_tab_menu_user_permission_list).map(([permission, value]) => (
                                                    <div className='col-lg-1' key={permission}>
                                                      <div className='form-group d-flex align-item-center'>
                                                        <input
                                                          type='checkbox'
                                                          name={`role_${index}_${subIndex}_${subTabIndex}_${permission}`}
                                                          checked={checked[item.id]?.[sub.sub_menu_id]?.[subTab.tab_menu_id]?.[permission] === 1}
                                                          onChange={(e) => handleChange(e, item.id, sub.sub_menu_id, subTab.tab_menu_id, undefined, permission)} />
                                                        <label className='selectlabel ml-2 mt-1'>{permission.charAt(3).toUpperCase() + permission.slice(4)}</label>
                                                      </div>
                                                    </div>
                                                  ))}
                                                </div>
                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        ))}
                                      </Accordion>
                                    )}
                                  </Accordion.Body>
                                </Accordion.Item>
                              ))}
                            </Accordion>
                          )}
                        </>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
              {/* <button className='formBtn' type="submit">Submit</button>
    </form> */}
            </div>
          </div>
        </div>
        {/* {usertype === 'EMPLOYEE' && title && */}
          <button className='formBtn float-right mb-2' onClick={handleSubmit}>Save</button>
        {/* } */}
      </div>

    </div >
  )
}

export default AddRolePermission