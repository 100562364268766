import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { AddStudentEnrollRequest, FetchstudentRequest, GetGradeRequest, GetSchoolRequest, GetStateRequest, GetTeacherRequest, GetcityRequest, GetCountryRequest, AddSchoolRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import moment from "moment";
import ApiConnection from '../../utils/ApiConnection'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-bootstrap/Modal';


const StudentEnrollDetails = () => {
    const [search, setsearch] = useState("")
    const [Namesearch, setNamesearch] = useState("")
    const [FilterResult, setFilterResult] = useState([])
    const [firstname, setfirstname] = useState("")
    const [lastname, setlastname] = useState("")
    const [gender, setgender] = useState("")
    const [dob, setdob] = useState("")
    const [studentemail, setstudentemail] = useState("")
    const [stateList, setstateList] = useState([])
    const [cityList, setcityList] = useState([])
    const [teacherList, setteacherList] = useState([])
    const [grade, setgrade] = useState("")
    const [schoolyear, setschoolyear] = useState("")
    const [schoolyear1, setschoolyear1] = useState("")
    const [school, setschool] = useState("")
    const [teacher, setteacher] = useState("")
    const [Prospectfirstname, setProspectfirstname] = useState("")
    const [Prospectlastname, setProspectlastname] = useState("")
    const [ProspectDOB, setProspectDOB] = useState("")
    const [Prospectemail, setProspectemail] = useState("")
    const [Prospectphone, setProspectphone] = useState("")
    const [Prospectadd1, setProspectadd1] = useState("")
    const [Prospectadd2, setProspectadd2] = useState("")
    const [Prospectcountry, setProspectcountry] = useState("")
    const [Prospectstate, setProspectstate] = useState("")
    const [Prospectcity, setProspectcity] = useState("")
    const [Prospectpincode, setProspectpincode] = useState("")
    const [Billingfirstname, setBillingfirstname] = useState("")
    const [Billinglastname, setBillinglastname] = useState("")
    const [Billingemail, setBillingemail] = useState("")
    const [Billingphone, setBillingphone] = useState("")
    const [Billingadd1, setBillingadd1] = useState("")
    const [Billingadd2, setBillingadd2] = useState("")
    const [Billingcountry, setBillingcountry] = useState("")
    const [Billingcity, setBillingcity] = useState("")
    const [Billingstate, setBillingstate] = useState("")
    const [Billingpincode, setBillingpincode] = useState("")
    const [enrolltype, setenrolltype] = useState("")
    const [enrollmonthlyamount, setenrollmonthlyamount] = useState("")
    const [enrolltotalsession, setenrolltotalsession] = useState("")
    const [enrollremainings, setenrollremainings] = useState("")
    const [enrollholdlength, setenrollholdlength] = useState("")
    const [enrolldelivery, setenrolldelivery] = useState("")
    const [enrollstartdate, setenrollstartdate] = useState(null)
    const [enrolloed, setenrolloed] = useState("")
    const [enrollinactivedate, setenrollinactivedate] = useState(null)
    const [enrollrecurring, setenrollrecurring] = useState("")
    const [servicename, setservicename] = useState("");
    const [pricename, setpricename] = useState("");
    const [discount, setdiscount] = useState("");
    const [discountAmount, setdiscountAmount] = useState("");
    const [discountPercentage, setdiscountPercentage] = useState("");
    const [name1, setname1] = useState("");
    const [price1, setprice1] = useState("");
    const [monthlyprice, setmonthlyprice] = useState("");
    const [session1, setsession1] = useState("");
    const [recurring1, setrecurring1] = useState("");
    const [subjectList, setsubjectList] = useState([])
    const [discountList, setdiscountList] = useState([])
    const [serviceList, setserviceList] = useState([])
    const [priceList, setpriceList] = useState([])
    const [EnrollmentList, setEnrollmentList] = useState([])
    const [inpersonsign, setinpersonsign] = useState("");
    const [onlinesign, setonlinesign] = useState("");
    const [mediasign, setmediasign] = useState("");
    const [teachersign, setteachersign] = useState("");
    const [leavesign, setleavesign] = useState("");
    const [inpersonchk, setinpersonchk] = useState("");
    const [onlinechk, setonlinechk] = useState("");
    const [mediachk, setmediachk] = useState("");
    const [teacherchk, setteacherchk] = useState("");
    const [leavechk, setleavechk] = useState("");
    const [inpersontext, setinpersontext] = useState("");
    const [onlinetext, setonlinetext] = useState("");
    const [mediatext, setmediatext] = useState("");
    const [teachertext, setteachertext] = useState("");
    const [leavetext, setleavetext] = useState("");
    const [inpersonnotes, setinpersonnotes] = useState("");
    const [onlinenotes, setonlinenotes] = useState("");
    const [medianotes, setmedianotes] = useState("");
    const [teachernotes, setteachernotes] = useState("");
    const [leavenotes, setleavenotes] = useState("");
    const [enrollmanualfee, setenrollmanualfee] = useState("");
    const [contactstartdate, setcontactstartdate] = useState("");
    const [contactenddate, setcontactenddate] = useState("");
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [schoolname, setschoolname] = useState("")
    const [schoolphone, setschoolphone] = useState("")
    const [schoolemail, setschoolemail] = useState("")
    const [schoolcontactperson, setschoolcontactperson] = useState("")
    const [schooladdress, setschooladdress] = useState("")
    const [schoolwebsite, setschoolwebsite] = useState("")
    const [content1, setContent1] = useState(true);
    const [content2, setContent2] = useState(false);
    const [content3, setContent3] = useState(false);
    const [content4, setContent4] = useState(false);
    const [content5, setContent5] = useState(false);
    const [content6, setContent6] = useState(false);
    const [subject, setsubject] = useState("");
    const [achdiscount, setachdiscount] = useState("");
    const [achdiscountamount, setachdiscountamount] = useState("");
    const [achdiscountamountprice, setachdiscountamountprice] = useState("");
    const [Amount1, setAmount1] = useState("");
    const [Amount2, setAmount2] = useState("");
    const [TodaysAmount, setTodaysAmount] = useState("");
    const [enrollregfee, setenrollregfee] = useState("");
    const [enrollassessmentfee, setenrollassessmentfee] = useState("");
    const [paymentmethod, setpaymentmethod] = useState("");
    const [companyid, setcompanyid] = useState("");
    const [achaccepted, setachaccepted] = useState("");
    const [scheduleduration, setscheduleduration] = useState("");
    const [ACHDiscountAmount, setACHDiscountAmount] = useState("");


    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var gradeList = fetchacompanyReducer?.GetGradeResponse?.results
    var schoolList = fetchacompanyReducer?.GetSchoolResponse?.results
    var studentteacherList = fetchacompanyReducer?.GetTeacherResponse?.results
    var countryList = fetchacompanyReducer?.GetcountryResponse



    console.log(fetchacompanyReducer.GetBackgroundResponse)

    const dispatch = useDispatch()
    let navigate = useNavigate()
    const { id } = useParams()

    useEffect(() => {

        var editdata = fetchacompanyReducer?.FetchstudentResponse?.results[0]
        console.log(editdata?.school_year)
        setfirstname(editdata?.first_name)
        setlastname(editdata?.last_name)
        setgender(editdata?.gender)
        setgrade(editdata?.grade_id)
        setschool(editdata?.school_id)
        setteacher(editdata?.school_teacher_id)
        setschoolyear(editdata?.school_year)
        setdob(moment(editdata?.date_of_birth).format('YYYY-MM-DD'))
        setstudentemail(editdata?.email)

        setProspectfirstname(editdata?.prospect_first_name)
        setProspectlastname(editdata?.prospect_last_name)
        setProspectemail(editdata?.prospect_email)
        setProspectphone(editdata?.prospect_mobile_phone)
        setProspectadd1(editdata?.prospect_street_address_1)
        setProspectadd2(editdata?.prospect_street_address_2)
        setProspectcountry(editdata?.prospect_country_id)
        setProspectcity(editdata?.prospect_city_id)
        setProspectstate(editdata?.prospect_state_id)
        setProspectpincode(editdata?.prospect_zipcode)

        if (editdata && editdata?.leave_unescorted_consent_form) {
            const enrollmentConfirmation = editdata.leave_unescorted_consent_form;
            function decodeHTMLEntities(text) {
                var textArea = document.createElement('textarea');
                textArea.innerHTML = text;
                return textArea.value;
            }
            const formattedText = decodeHTMLEntities(enrollmentConfirmation.replace(/<\/?[^>]+(>|$)/g, ""));
            setleavetext(formattedText);
        }

        if (editdata && editdata?.teacher_consent_form) {
            const enrollmentConfirmation = editdata.teacher_consent_form;
            function decodeHTMLEntities(text) {
                var textArea = document.createElement('textarea');
                textArea.innerHTML = text;
                return textArea.value;
            }
            const formattedText = decodeHTMLEntities(enrollmentConfirmation.replace(/<\/?[^>]+(>|$)/g, ""));
            setteachertext(formattedText);
        }

        if (editdata && editdata?.media_release_form) {
            const enrollmentConfirmation = editdata.media_release_form;
            function decodeHTMLEntities(text) {
                var textArea = document.createElement('textarea');
                textArea.innerHTML = text;
                return textArea.value;
            }
            const formattedText = decodeHTMLEntities(enrollmentConfirmation.replace(/<\/?[^>]+(>|$)/g, ""));
            setmediatext(formattedText);
        }

        if (editdata && editdata?.learning_center_guidelines) {
            const enrollmentConfirmation = editdata.learning_center_guidelines;
            function decodeHTMLEntities(text) {
                var textArea = document.createElement('textarea');
                textArea.innerHTML = text;
                return textArea.value;
            }
            const formattedText = decodeHTMLEntities(enrollmentConfirmation.replace(/<\/?[^>]+(>|$)/g, ""));
            setinpersontext(formattedText);
        }

        if (editdata && editdata?.home_learning_center_guidelines) {
            const enrollmentConfirmation = editdata.home_learning_center_guidelines;
            function decodeHTMLEntities(text) {
                var textArea = document.createElement('textarea');
                textArea.innerHTML = text;
                return textArea.value;
            }
            const formattedText = decodeHTMLEntities(enrollmentConfirmation.replace(/<\/?[^>]+(>|$)/g, ""));
            setonlinetext(formattedText);
        }


        let obj = {
            id: editdata?.prospect_country_id,
        };
        dispatch(GetStateRequest(obj));

        let obj1 = {
            id: editdata?.prospect_state_id,
        };
        dispatch(GetcityRequest(obj1));

        setBillingfirstname(editdata?.prospect_first_name)
        setBillinglastname(editdata?.prospect_last_name)
        setBillingemail(editdata?.prospect_email)
        setBillingphone(editdata?.prospect_mobile_phone)
        setBillingadd1(editdata?.prospect_street_address_1)
        setBillingadd2(editdata?.prospect_street_address_2)
        setBillingcountry(editdata?.prospect_country_id)
        setBillingstate(editdata?.prospect_state_id)
        setBillingcity(editdata?.prospect_city_id)
        setBillingpincode(editdata?.prospect_zipcode)

        setenrollstartdate()
        setenrollinactivedate()
        setenrollrecurring()
        setenrolltype()
        setenrollregfee(editdata?.startup_fee)
        setenrolltotalsession()
        setenrollassessmentfee(editdata?.assessment_fee)
        setenrollmonthlyamount(editdata?.cancelation_fee)
        setenrolldelivery()
        setenrollremainings(editdata?.late_fee)
        setenrolltotalsession()
        setenrollmanualfee(editdata?.manual_payment_fee)
        setachdiscountamount(editdata?.ach_discount)
        setachaccepted(editdata?.is_ach_accepted)
        setinpersonnotes(editdata?.learning_center_guidelines_notes)
        setonlinenotes(editdata?.online_learning_guidelines_notes)
        setmedianotes(editdata?.media_release_form_notes)
        setteachernotes(editdata?.teacher_consent_form_notes)
        setleavenotes(editdata?.leave_unescorted_consent_form_notes)
        setcompanyid(editdata?.company_id)




    }, [fetchacompanyReducer?.FetchstudentResponse])

    const GetTeacher = async () => {
        try {
          const response = await ApiConnection.get(`employee-time-schedule/emp-list/?server_type=production`);
          if (response?.status === 200) {
            setteacherList(response?.data?.results);
          }
        } catch (e) { }
      };

    const handleShow1 = (event) => {
        setschool(event.target.value)
        const selectValue = event.target.value
        if (selectValue === 'addschool') {
            setShow1(true);
        }
    };

    const handleClose = () => {
        setShow1(false);
        dispatch(GetSchoolRequest())
    }

    const handleShow2 = (event) => {
        setteacher(event.target.value)
        const selectValue = event.target.value
        if (selectValue === 'addteacher') {
            setShow2(true);
        }
    };

    const handleClose2 = () => {
        setShow2(false);
        dispatch(GetTeacherRequest())
    }

    const SubmitSchool = () => {
        if (schoolname == '') {
            Swal.fire({
                icon: "error",
                text: 'School Name should be must',
            });
        }

        else {
            let obj = {
                "name": schoolname,
                "email": schoolemail,
                "address": schooladdress,
                "phone_no": schoolphone,
                "contact_person_name": schoolcontactperson,
                "website": schoolwebsite
            }

            dispatch(AddSchoolRequest({ obj: obj, navigate: navigate }))
        }
    };

    useEffect(() => {

        setFilterResult(EnrollmentList)
        GetEnrollmentList()
        GetTeacher()
    }, [])

    const SubjectHandle = async (e) => {
        var val = e.target.value
        setsubject(val)
        try {
            const response = await ApiConnection.get(`service_crud/?server_type=production&subject_id=${val}`,)
            if (response?.status == 200) {
                setserviceList(response?.data?.results)
                setname1("");
                setsession1("");
                setprice1("");
                setrecurring1("");
            }
        } catch (e) { }
    }

    const ServiceHandle = async (e) => {
        console.log("ServiceHandle", e)
        var val = e.target.value
        setservicename(val)
        try {
            const response = await ApiConnection.get(`pricing_crud/?server_type=production&service_id=${val}`)
            console.log(response.data.results[0].name);
            setpriceList(response?.data?.results)
            console.log("priceList", priceList)

            setsession1("");
            setprice1("");
            setrecurring1("");


        } catch (e) { }
    }

    const PriceHandle = async (e) => {
        console.log("PriceHandle", e)
        var val = e.target.value
        setpricename(val)
        console.log("PriceHandleVal", val)

        try {
            const response = await ApiConnection.get(`pricing_crud/?server_type=production&id=${val}`)
            console.log(response);

            setsession1(response.data.results[0].total_session);
            setprice1(response.data.results[0].price);
            setrecurring1(response.data.results[0].recurring);


        } catch (e) { }
    }

    const GetDiscount = async (e) => {

        try {
            const response = await ApiConnection.get(`membership-discount-crud/?server_type=production`)
            if (response?.status == 200) {
                setdiscountList(response?.data?.results)

            }
        } catch (e) { }

    }

    const DiscountHandle = async (e) => {
        console.log("ServiceHandle", e)
        var val = e.target.value
        setdiscount(val)
        try {
            const response = await ApiConnection.get(`membership-discount-crud/?server_type=production&id=${val}`)
            console.log(response.data.results[0].name);

            setdiscountAmount(response.data.results[0].amount);
            setdiscountPercentage(response.data.results[0].is_percentage);

        } catch (e) { }

    }


    const GetSubject = async () => {
        let obj = {
            name: '',
            group_id: ''
        }
        try {
            const response = await ApiConnection.get(`subjects-crud/?server_type=production&name=${obj.name}&group_id=${obj.group_id}`)
            if (response?.status == 200) {
                console.log("get subject", response.data)
                setsubjectList(response?.data?.results)
            }
        } catch (e) { }
    }

    const GetEnrollmentList = async () => {

        try {
            const response = await ApiConnection.get(`student-admission-crud/?server_type=production&student_id=${id}`)
            if (response?.status == 200) {
                console.log("get Enrollment", response)
                setEnrollmentList(response?.data?.results)
            }
        } catch (e) { }
    }

    useEffect(() => {
        let obj = {
            id: id
        }
        dispatch(FetchstudentRequest(obj))
        dispatch(GetGradeRequest())
        dispatch(GetSchoolRequest())
        dispatch(GetTeacherRequest())
        dispatch(GetCountryRequest())
        dispatch(GetStateRequest())
        dispatch(GetcityRequest())
        GetSubject();
        GetService();
        GetEnrollmentList();
        GetDiscount();
    }, [])

    useEffect(() => {
        setstateList(fetchacompanyReducer?.GetstateResponse)
    }, [fetchacompanyReducer?.GetstateResponse])

    useEffect(() => {
        setcityList(fetchacompanyReducer?.GetcityResponse)
    }, [fetchacompanyReducer?.GetcityResponse])

    const CountryHandle = (e) => {
        setProspectcountry(e.target.value)
        let obj = {
            id: e.target.value
        }
        dispatch(GetStateRequest(obj))
    }

    const stateHandle = (e) => {
        setProspectstate(e.target.value)
        let obj = {
            id: e.target.value
        }
        dispatch(GetcityRequest(obj))
    }

    const BillingCountryHandle = (e) => {
        setBillingcountry(e.target.value)
        let obj = {
            id: e.target.value
        }
        dispatch(GetStateRequest(obj))
    }

    const BillingStateHandle = (e) => {
        setBillingstate(e.target.value)
        let obj = {
            id: e.target.value
        }
        dispatch(GetcityRequest(obj))
    }

    const GetService = async () => {
        try {
            const response = await ApiConnection.get(`service_crud/?server_type=production`)
            if (response?.status == 200) {
                setserviceList(response?.data?.results)
            }
        } catch (e) { }
    }

    const ProratedamountHandle = (value) => {
        setenrolltotalsession(value)
        const newAmount = Math.floor(price1 / session1 * value);
        setenrolltype(newAmount);
        const newAmount1 = Math.floor(newAmount + enrollregfee + enrollassessmentfee);
        setAmount1(newAmount1);
        const discounttotal = Math.floor(newAmount1 - discountAmount);
        setAmount2(discounttotal)
        const AchdiscountPrice = Math.floor(TodaysAmount * achdiscountamount) / 100;
        setachdiscountamountprice(AchdiscountPrice)
        const Achdiscounttotal = Math.floor(discounttotal - AchdiscountPrice);
        setTodaysAmount(Achdiscounttotal)
        console.log('discounttotal', discounttotal)
    }

    // const ProratedamountHandle = (value) => {
    //     setenrolltotalsession(value);
    //     const newAmount = Math.floor(price1 / session1 * value);
    //     setenrolltype(newAmount);
    //     const newAmount1 = Math.round(newAmount + enrollregfee + enrollassessmentfee); // Round this value
    //     setAmount1(newAmount1);
    //     const discounttotal = Math.round(newAmount1 - discountAmount); // Round this value
    //     setAmount2(discounttotal);
    //     const AchdiscountPrice = Math.round((TodaysAmount * achdiscountamount) / 100); // Round this value
    //     setachdiscountamountprice(AchdiscountPrice);
    //     const Achdiscounttotal = Math.round(discounttotal - AchdiscountPrice); // Round this value
    //     setTodaysAmount(Achdiscounttotal);
    // }

    const TotalAmountHandle = (value) => {
        setenrolltotalsession(value)
        const newAmount = Math.floor(price1 / session1 * value);
        setenrolldelivery(newAmount);

    }


    const SubmitHandle = () => {
        if (firstname == '') {
            Swal.fire({
                icon: "error",
                text: 'Students First Name should be must',
            });
        } else if (lastname == '') {
            Swal.fire({
                icon: "error",
                text: 'Students Last Name should be must',
            });
        } else if (gender == '') {
            Swal.fire({
                icon: "error",
                text: 'Students Gender Name should be must',
            });
        } else if (dob == '') {
            Swal.fire({
                icon: "error",
                text: 'Students DOB should be must',
            });
        } else if (grade == '') {
            Swal.fire({
                icon: "error",
                text: 'Students Grade should be must',
            });
        } else if (schoolyear == '') {
            Swal.fire({
                icon: "error",
                text: 'Students School Year should be must',
            });
        } else if (Prospectfirstname == '') {
            Swal.fire({
                icon: "error",
                text: 'Prospects First Name should be must',
            });
        } else if (Prospectlastname == '') {
            Swal.fire({
                icon: "error",
                text: 'Prospects Last Name should be must',
            });
        } else if (Prospectemail == '') {
            Swal.fire({
                icon: "error",
                text: 'Prospects Email should be must',
            });
        } else if (Prospectphone == '') {
            Swal.fire({
                icon: "error",
                text: 'Prospects Mobile No. should be must',
            });
        } else if (Prospectadd1 == '') {
            Swal.fire({
                icon: "error",
                text: 'Prospects Address 1 should be must',
            });
        } else if (Prospectcountry == '') {
            Swal.fire({
                icon: "error",
                text: 'Prospects Prospects Country should be must',
            });
        } else if (Prospectstate == '') {
            Swal.fire({
                icon: "error",
                text: 'Prospects State should be must',
            });
        } else if (Prospectcity == '') {
            Swal.fire({
                icon: "error",
                text: 'Prospects City should be must',
            });
        } else if (Prospectpincode == '') {
            Swal.fire({
                icon: "error",
                text: 'Prospects Pincode should be must',
            });
        } else if (Billingfirstname == '') {
            Swal.fire({
                icon: "error",
                text: 'Billing First Name should be must',
            });
        } else if (Billinglastname == '') {
            Swal.fire({
                icon: "error",
                text: 'Billing Last Name should be must',
            });
        } else if (Billingemail == '') {
            Swal.fire({
                icon: "error",
                text: 'Billing Email should be must',
            });
        } else if (Billingphone == '') {
            Swal.fire({
                icon: "error",
                text: 'Billing Mobile No. should be must',
            });
        } else if (Billingadd1 == '') {
            Swal.fire({
                icon: "error",
                text: 'Billing Address 1 should be must',
            });
        } else if (Billingcountry == '') {
            Swal.fire({
                icon: "error",
                text: 'Billing Country should be must',
            });
        } else if (Billingstate == '') {
            Swal.fire({
                icon: "error",
                text: 'Billing State should be must',
            });
        } else if (Billingcity == '') {
            Swal.fire({
                icon: "error",
                text: 'Billing City should be must',
            });
        } else if (Billingpincode == '') {
            Swal.fire({
                icon: "error",
                text: 'Billing Pincode should be must',
            });
            // } else if (inpersonchk == '') {
            //     Swal.fire({
            //         icon: "error",
            //         text: 'Inperson T&C should be must',
            //     });
            // } else if (inpersonsign == '') {
            //     Swal.fire({
            //         icon: "error",
            //         text: 'Inperson initials should be must',
            //     });
            // } else if (onlinechk == '') {
            //     Swal.fire({
            //         icon: "error",
            //         text: 'Online T&C should be must',
            //     });
            // } else if (onlinesign == '') {
            //     Swal.fire({
            //         icon: "error",
            //         text: 'Online initials should be must',
            //     });
            // } else if (mediachk == '') {
            //     Swal.fire({
            //         icon: "error",
            //         text: 'Media T&C should be must',
            //     });
            // } else if (mediasign == '') {
            //     Swal.fire({
            //         icon: "error",
            //         text: 'Media initials should be must',
            //     });
            // } else if (teacherchk == '') {
            //     Swal.fire({
            //         icon: "error",
            //         text: 'Teacher T&C should be must',
            //     });
            // } else if (teachersign == '') {
            //     Swal.fire({
            //         icon: "error",
            //         text: 'Teacher initials should be must',
            //     });
            // } else if (leavechk == '') {
            //     Swal.fire({
            //         icon: "error",
            //         text: 'leave T&C should be must',
            //     });
            // } else if (leavesign == '') {
            //     Swal.fire({
            //         icon: "error",
            //         text: 'leave initials should be must',
            //     });
        } else if (subject == '') {
            Swal.fire({
                icon: "error",
                text: 'subject should be must',
            });
        } else if (servicename == '') {
            Swal.fire({
                icon: "error",
                text: 'service name should be must',
            });
        } else if (pricename == '') {
            Swal.fire({
                icon: "error",
                text: 'Price name should be must',
            });
        } else if (session1 == '') {
            Swal.fire({
                icon: "error",
                text: 'Total session should be must',
            });
        } else if (price1 == '') {
            Swal.fire({
                icon: "error",
                text: 'price should be must',
            });
        } else if (recurring1 == '') {
            Swal.fire({
                icon: "error",
                text: 'recurring should be must',
            });
        } else if (contactstartdate == '') {
            Swal.fire({
                icon: "error",
                text: 'contact start date should be must',
            });
        } else if (enrollstartdate == '') {
            Swal.fire({
                icon: "error",
                text: 'enroll start date should be must',
            });
        } else if (enrollinactivedate == '') {
            Swal.fire({
                icon: "error",
                text: 'enroll inactive date should be must',
            });
        } else if (enrollrecurring == '') {
            Swal.fire({
                icon: "error",
                text: 'enroll recurring should be must',
            });
        } else if (enrolltype == '') {
            Swal.fire({
                icon: "error",
                text: 'enroll type should be must',
            });
        } else if (enrolltotalsession == '') {
            Swal.fire({
                icon: "error",
                text: 'enroll total session should be must',
            });
        } else if (enrollmanualfee == '') {
            Swal.fire({
                icon: "error",
                text: 'enroll manual fee should be must',
            });
        } else if (enrollremainings == '') {
            Swal.fire({
                icon: "error",
                text: 'enroll remainings should be must',
            });
        } else if (enrolldelivery == '') {
            Swal.fire({
                icon: "error",
                text: 'enroll delivery should be must',
            });
        } else {

            let obj = {
                "first_name": firstname,
                "last_name": lastname,
                "gender": gender,
                "date_of_birth": dob,
                "grade_id": grade,
                "school_id": school,
                "school_teacher_id": teacher,
                "school_year": '2024',
                "school_year": '2025',
                "std_email": studentemail,
                "guardian_first_name": Prospectfirstname,
                "guardian_last_name": Prospectlastname,
                "guardian_email": Prospectemail,
                "guardian_mobile_phone": Prospectphone,
                "street_address_1": Prospectadd1,
                "street_address_2": Prospectadd2,
                "country_id": Prospectcountry,
                "state_id": Prospectstate,
                "city_id": Prospectcity,
                "zipcode": Prospectpincode,
                "location_id": 1,
                "company_id": companyid,
                "student_id": id,
                "guardian_id": 1,
                "billing_first_name": Billingfirstname,
                "billing_last_name": Billinglastname,
                "billing_email": Billingemail,
                "billing_phone": Billingphone,
                "billing_address_1": Billingadd1,
                "billing_address_2": Billingadd2,
                "billing_country_id": Billingcountry,
                "billing_state_id": Billingstate,
                "billing_pincode": Billingpincode,
                "in_person_learning_guidelines": inpersontext,
                "in_person_learning_guidelines_is_accept": 1,
                "in_person_learning_guidelines_is_opt_in": 1,
                "in_person_learning_guidelines_signature": inpersonsign,
                "online_learning_guidelines": onlinetext,
                "online_learning_guidelines_is_accept": 1,
                "online_learning_guidelines_is_opt_in": 1,
                "online_learning_guidelines_signature": onlinesign,
                "media_release_form": mediatext,
                "media_release_form_is_accept": 1,
                "media_release_form_is_opt_in": 1,
                "media_release_form_signature": mediasign,
                "teacher_consent_form": teachertext,
                "teacher_consent_form_is_accept": 1,
                "teacher_consent_form_is_opt_in": 1,
                "teacher_consent_form_signature": teachersign,
                "leave_unescorted_consent_form": leavetext,
                "leave_unescorted_consent_form_is_accept": 1,
                "leave_unescorted_consent_form_signature": leavesign,
                "subject_id": subject,
                "service_id": servicename,
                "name": pricename,
                "total_session": session1,
                "price": price1,
                "recurring": recurring1,
                "startup_fee": enrollstartdate,
                "registration_fee": enrollregfee,
                "assessment_fee": enrollassessmentfee,
                "monthly_recurring_fee": price1,
                "contract_start_date": contactstartdate,
                "contract_end_date": contactenddate,
                "discount_id": discount,
                "dicount_amount": discountAmount,
                "discount_percentage": discountPercentage,
                "prorated_first_month": enrolltype,
                "prorated_first_session": enrolltotalsession,
                "account_closing_fee": enrollmonthlyamount,
                "manual_fee": enrollmanualfee,
                "ach_discount": achdiscountamount,
                "late_payment_fee": enrollremainings,
                "todays_total_fee": enrolldelivery,
                "website_url": "qwerty",
                "todays_total_fee": TodaysAmount,
            }

            dispatch(AddStudentEnrollRequest({ obj: obj, student_id: id, navigate: navigate }))

        }
    }

    const studentinfoSubmit = async () => {
        try {
            if (firstname == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Students First Name should be must',
                });
            } else if (lastname == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Students Last Name should be must',
                });
            } else if (gender == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Students Gender Name should be must',
                });
            } else if (dob == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Students DOB should be must',
                });
            } else if (grade == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Students Grade should be must',
                });
            } else if (schoolyear == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Students School Year should be must',
                });
            } else {


                let obj = {
                    "first_name": firstname,
                    "last_name": lastname,
                    "gender": gender,
                    "date_of_birth": dob,
                    "grade_id": grade,
                    "school_id": school,
                    "school_teacher_id": teacher,
                    // "schoolyear": '2024',
                    "school_year": schoolyear,
                    "std_email": studentemail,
                }

                const response = await ApiConnection.post(`admission/student-info/?server_type=production&student_id=${id}`, obj);
                // if (response?.status == 500) {
                //     Swal.fire({
                //         icon: "success",
                //         text: response?.data?.msg,
                //     });
                // }
                setContent(2);
            }
        } catch (e) { }

    }

    const guardianinfoSubmit = async () => {
        try {
            if (Prospectfirstname == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Prospect First Name should be must',
                });
            } else if (Prospectlastname == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Prospects Last Name should be must',
                });
            } else if (Prospectemail == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Prospects Email should be must',
                });
            } else if (Prospectphone == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Prospects Mobile No. should be must',
                });
            } else if (Prospectadd1 == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Prospects Address 1 should be must',
                });
            } else if (Prospectcountry == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Prospects Prospects Country should be must',
                });
            } else if (Prospectstate == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Prospects State should be must',
                });
            } else if (Prospectcity == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Prospects City should be must',
                });
            } else if (Prospectpincode == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Prospects Pincode should be must',
                });
            } else {

                let obj = {
                    "guardian_first_name": Prospectfirstname,
                    "guardian_last_name": Prospectlastname,
                    "guardian_email": Prospectemail,
                    "guardian_mobile_phone": Prospectphone,
                    "street_address_1": Prospectadd1,
                    "street_address_2": Prospectadd2,
                    "country_id": Prospectcountry,
                    "state_id": Prospectstate,
                    "city_id": Prospectcity,
                    "zipcode": Prospectpincode,
                }

                const response = await ApiConnection.post(`admission/guardian-info/?server_type=production&student_id=${id}`, obj);
                // if (response?.status == 500) {
                //     Swal.fire({
                //         icon: "success",
                //         text: response?.data?.msg,
                //     });
                // }
                setContent(3);
            }
        } catch (e) { }
    }

    const billinginfoSubmit = async () => {
        try {
            if (Billingfirstname == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Billing First Name should be must',
                });
            } else if (Billinglastname == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Billing Last Name should be must',
                });
            } else if (Billingemail == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Billing Email should be must',
                });
            } else if (Billingphone == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Billing Mobile No. should be must',
                });
            } else if (Billingadd1 == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Billing Address 1 should be must',
                });
            } else if (Billingcountry == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Billing Country should be must',
                });
            } else if (Billingstate == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Billing State should be must',
                });
            } else if (Billingcity == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Billing City should be must',
                });
            } else if (Billingpincode == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Billing Pincode should be must',
                });
            } else {


                let obj = {
                    "billing_first_name": Billingfirstname,
                    "billing_last_name": Billinglastname,
                    "billing_email": Billingemail,
                    "billing_phone": Billingphone,
                    "billing_address_1": Billingadd1,
                    "billing_address_2": Billingadd2,
                    "billing_country_id": Billingcountry,
                    "billing_state_id": Billingstate,
                    "billing_city_id": Billingstate,
                    "billing_pincode": Billingpincode,
                }

                const response = await ApiConnection.post(`admission/billing-info/add/?server_type=production&student_id=${id}`, obj);
                // if (response?.status == 500) {
                //     Swal.fire({
                //         icon: "success",
                //         text: response?.data?.msg,
                //     });
                // }
                setContent(4);
            }
        } catch (e) { }
    }

    const termsinfoSubmit = async () => {
        try {
            if (inpersonsign == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Inperson initials should be must',
                });
                // } else if (onlinechk == '') {
                //     Swal.fire({
                //         icon: "error",
                //         text: 'Online T&C should be must',
                //     });
                } else if (onlinesign == '') {
                    Swal.fire({
                        icon: "error",
                        text: 'Online initials should be must',
                    });
                // } else if (mediachk == '') {
                //     Swal.fire({
                //         icon: "error",
                //         text: 'Media T&C should be must',
                //     });
                } else if (mediasign == '') {
                    Swal.fire({
                        icon: "error",
                        text: 'Media initials should be must',
                    });
                // } else if (teacherchk == '') {
                //     Swal.fire({
                //         icon: "error",
                //         text: 'Teacher T&C should be must',
                //     });
                } else if (teachersign == '') {
                    Swal.fire({
                        icon: "error",
                        text: 'Teacher initials should be must',
                    });
                // } else if (leavechk == '') {
                //     Swal.fire({
                //         icon: "error",
                //         text: 'leave T&C should be must',
                //     });
                } else if (leavesign == '') {
                    Swal.fire({
                        icon: "error",
                        text: 'leave initials should be must',
                    });
            } else {

            let obj = {
                "in_person_learning_guidelines": inpersontext,
                "in_person_learning_guidelines_is_accept": 1,
                "in_person_learning_guidelines_is_opt_in": 1,
                "in_person_learning_guidelines_signature": inpersonsign,
                "online_learning_guidelines": onlinetext,
                "online_learning_guidelines_is_accept": 1,
                "online_learning_guidelines_is_opt_in": 1,
                "online_learning_guidelines_signature": onlinesign,
                "media_release_form": mediatext,
                "media_release_form_is_accept": 1,
                "media_release_form_is_opt_in": 1,
                "media_release_form_signature": mediasign,
                "teacher_consent_form": teachertext,
                "teacher_consent_form_is_accept": 1,
                "teacher_consent_form_is_opt_in": 1,
                "teacher_consent_form_signature": teachersign,
                "leave_unescorted_consent_form": leavetext,
                "leave_unescorted_consent_form_is_accept": 1,
                "leave_unescorted_consent_form_signature": leavesign,
            }

            const response = await ApiConnection.post(`admission/terms-conditions-info/add/?server_type=production&student_id=${id}`, obj);
            // if (response?.status == 500) {
            //     Swal.fire({
            //         icon: "success",
            //         text: response?.data?.msg,
            //     });
            // }
            setContent(5);
            }
        } catch (e) { }
    }

    const serviceinfoSubmit = async () => {
        try {

            if (subject == '') {
                Swal.fire({
                    icon: "error",
                    text: 'subject should be must',
                });
            } else if (servicename == '') {
                Swal.fire({
                    icon: "error",
                    text: 'service name should be must',
                });
            } else if (pricename == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Price name should be must',
                });
            } else if (session1 == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Total session should be must',
                });
            } else if (price1 == '') {
                Swal.fire({
                    icon: "error",
                    text: 'price should be must',
                });
            } else if (recurring1 == '') {
                Swal.fire({
                    icon: "error",
                    text: 'recurring should be must',
                });
            } else if (contactstartdate == '') {
                Swal.fire({
                    icon: "error",
                    text: 'recurring start date should be must',
                });
            } else if (enrollstartdate == '') {
                Swal.fire({
                    icon: "error",
                    text: 'enroll start date should be must',
                });
            } else if (enrollinactivedate == '') {
                Swal.fire({
                    icon: "error",
                    text: 'enroll inactive date should be must',
                });
            } else if (enrollrecurring == '') {
                Swal.fire({
                    icon: "error",
                    text: 'enroll recurring should be must',
                });
            } else if (enrolltype == '') {
                Swal.fire({
                    icon: "error",
                    text: 'enroll type should be must',
                });
            } else if (enrolltotalsession == '') {
                Swal.fire({
                    icon: "error",
                    text: 'enroll total session should be must',
                });
            } else if (enrollmanualfee == '') {
                Swal.fire({
                    icon: "error",
                    text: 'enroll manual fee should be must',
                });
            } else if (enrollremainings == '') {
                Swal.fire({
                    icon: "error",
                    text: 'enroll remainings should be must',
                });
            } else if (enrolldelivery == '') {
                Swal.fire({
                    icon: "error",
                    text: 'enroll delivery should be must',
                });
            } else {
                let obj = {
                    // "student_id": id,
                    "company_id": companyid,
                    "subject_id": subject,
                    "service_id": servicename,
                    "pricing_id": pricename,
                    "total_session": session1,
                    "price": price1,
                    "recurring": recurring1,
                    "startup_fee": enrollstartdate,
                    "registration_fee": enrollregfee,
                    "assessment_fee": enrollassessmentfee,
                    "monthly_recurring_fee": price1,
                    "contract_start_date": contactstartdate,
                    "contract_end_date": contactenddate,
                    "discount_id": discount,
                    "dicount_amount": discountAmount,
                    "discount_percentage": discountPercentage,
                    "prorated_first_month": enrolltype,
                    "prorated_first_session": enrolltotalsession,
                    "account_closing_fee": enrollmonthlyamount,
                    "manual_fee": enrollmanualfee,
                    "ach_discount": achdiscountamount,
                    "late_payment_fee": enrollremainings,
                    // "todays_total_fee": TodaysAmount,
                    "todays_total_fee": paymentmethod === '4' 
                    ? Math.max(TodaysAmount - achdiscountamount, 0) // ACH discount applied
                    : Amount2, // Non-ACH
                    "service_durations": scheduleduration
                }

                // if (paymentmethod === '4') {
                //     const AchdiscountPrice = Math.round((TodaysAmount * achdiscountamount) / 100);
                //     const totalWithAchDiscount = TodaysAmount - AchdiscountPrice;
                //     console.log(AchdiscountPrice, totalWithAchDiscount, TodaysAmount)
                //     obj.todays_total_fee = Math.max(totalWithAchDiscount, 0);
                // } else {
                //     obj.todays_total_fee = TodaysAmount;
                // }

                const response = await ApiConnection.post(`admission/service-info/add/?server_type=production&student_id=${id}`, obj);
                if (response?.status == 200) {
                    Swal.fire({
                        icon: "success",
                        text: response?.data?.msg,
                    });
                    navigate(`/${userinfo?.company_slug}/students/PaymentLink/${obj.service_id}/${obj.company_id}/${id}`)
                }
                // setContent(5);
            }
        } catch (e) { }
    }
    // const Content_6 =
    //     "Content 6";

    function setContent(id) {

        setContent1(id === 1);
        setContent2(id === 2);
        setContent3(id === 3);
        setContent4(id === 4);
        setContent5(id === 5);
        setContent6(id === 6);
    }

    const handlePaymentMethodChange = (e) => {
        const selectedMethod = e.target.value;
        setpaymentmethod(selectedMethod); 
            if (selectedMethod === '4') { 
                const discountedPrice = calculateACHDiscountedPrice();
                setTodaysAmount(discountedPrice);
                setmonthlyprice(price1 - ACHDiscountAmount);
        } else {
            setTodaysAmount(Amount2);
            setmonthlyprice(price1);

        }
    };

        const calculateACHDiscountedPrice = () => {
            if (Amount2 && achdiscountamount) {
                const discountAmount = (achdiscountamount / 100) * Amount2;
                setACHDiscountAmount(discountAmount);
                return Amount2 - discountAmount;
            }
            return Amount2;
        };


    return (



        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />

            {content1 &&
                <div className='innerlayout p-3'>
                    <div className='mb-4'>
                        <h3> Enrollment Details</h3>
                    </div>
                    <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-67px' }}>
                        <ul>
                            <li>
                                <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                            </li>
                        </ul>
                    </div>

                    <label className='selectlabel' style={{ fontSize: '16px' }}>Student Information</label>

                    <div className='row form-style mb-4'>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>First Name<span class="asterisk">*</span></label>
                                <input type='text' className='form-control' placeholder='First Name'
                                    value={firstname}
                                    onChange={(e) => setfirstname(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>last Name<span class="asterisk">*</span></label>
                                <input type='text' className='form-control' placeholder='Last Name'
                                    value={lastname}
                                    onChange={(e) => setlastname(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Gender<span class="asterisk">*</span></label>
                                <input type='text' className='form-control' placeholder='Gender'
                                    value={gender}
                                    onChange={(e) => setgender(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>DOB<span class="asterisk">*</span></label>
                                <input type='date' className='form-control' placeholder='DOB'
                                    value={dob}
                                    onChange={(e) => setdob(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Grade<span class="asterisk">*</span></label>
                                <select className='form-control' value={grade}
                                    onChange={(e) => setgrade(e.target.value)}>
                                    <option value="">--Grade Name---</option>
                                    {gradeList && gradeList.map((item, i) => {
                                        return (
                                            <option key={i} value={item?.id}>{item?.grade_name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Email</label>
                                <input type='text' className='form-control' placeholder='Email'
                                    value={studentemail}
                                    onChange={(e) => setstudentemail(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>School Year<span class="asterisk">*</span></label>
                                <input type='text' className='form-control' placeholder='School Year' value={schoolyear}
                                    onChange={(e) => setschoolyear(e.target.value)}
                                />
                            </div>
                        </div>
                        {/* <span className='mt-5'>To</span>
                        <div className='col-lg-2 mt-4'>
                            <div className='form-group'>
                                <DatePicker type='text' className='form-control' placeholder='School Year'
                                    dateFormat="yyyy" showYearPicker selected={schoolyear1} value={moment(schoolyear).format('YYYY')}
                                    onChange={(date) => setschoolyear1(date)}
                                />
                            </div>
                        </div> */}

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Select School Name <span class="asterisk">*</span></label>
                                <select className='form-control' value={school}
                                    onChange={handleShow1}>
                                    <option value="">-- Select School --</option>
                                    <option value="addschool" style={{ color: 'blue' }}>Add New School</option>
                                    {schoolList && schoolList.map((school, i) => {
                                        return (
                                            <option key={i} value={school?.id}>{school?.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Teacher</label>
                                <select className='form-control' value={teacher}
                                    onChange={handleShow2}>
                                    <option value="">-- Select Teacher --</option>
                                    {studentteacherList && studentteacherList.map((school, i) => {
                                        return (
                                            <option key={i} value={school?.id}>{school?.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>

                    </div>
                    <button className='formBtn mr-3 mb-4 float-right' onClick={studentinfoSubmit}>Next</button>
                </div>
            }

            {content2 &&
                <div className='innerlayout p-3'>
                    <div className='mb-4'>
                        <h3> Enrollment Details</h3>
                    </div>
                    <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-67px' }}>
                        <ul>
                            <li>
                                <button className='formBtn1' onClick={() => setContent(1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                            </li>
                        </ul>
                    </div>
                    <label className='selectlabel' style={{ fontSize: '16px' }}>Guardian Information</label>

                    <div className='row form-style mb-4'>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>First Name<span class="asterisk">*</span></label>
                                <input type='text' className='form-control' placeholder='First Name'
                                    value={Prospectfirstname}
                                    onChange={(e) => setProspectfirstname(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Last Name<span class="asterisk">*</span></label>
                                <input type='text' className='form-control' placeholder='Last Name'
                                    value={Prospectlastname}
                                    onChange={(e) => setProspectlastname(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Email<span class="asterisk">*</span></label>
                                <input type='text' disabled className='form-control' placeholder='Email'
                                    value={Prospectemail}
                                    onChange={(e) => setProspectemail(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Mobile No.<span class="asterisk">*</span></label>
                                <input type='text' className='form-control' placeholder='Mobile No.'
                                    value={Prospectphone} maxLength={10}
                                    onChange={(e) => setProspectphone(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Address 1<span class="asterisk">*</span></label>
                                <input type='text' className='form-control' placeholder='Address 1'
                                    value={Prospectadd1}
                                    onChange={(e) => setProspectadd1(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Address 2</label>
                                <input type='text' className='form-control' placeholder='Address 2'
                                    value={Prospectadd2}
                                    onChange={(e) => setProspectadd2(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Country<span class="asterisk">*</span></label>
                                <select className='form-control' value={Prospectcountry}
                                    onChange={CountryHandle}>
                                    <option value="">--Select country---</option>
                                    {countryList && countryList.map((list, i) => {
                                        return (
                                            <option key={i} value={list?.id}>{list?.name}</option>
                                        )

                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>State<span class="asterisk">*</span></label>
                                <select className='form-control' value={Prospectstate}
                                    onChange={stateHandle}>
                                    <option value="">--Select State---</option>
                                    {stateList && stateList.map((list, i) => {
                                        return (
                                            <option key={i} value={list?.state_id}>{list?.name}</option>
                                        )

                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>City<span class="asterisk">*</span></label>
                                <select className='form-control' value={Prospectcity}
                                    onChange={(e) => setProspectcity(e.target.value)}>
                                    <option value="">--Select City---</option>
                                    {cityList && cityList.map((list, i) => {
                                        return (
                                            <option key={i} value={list?.city_id}>{list?.name}</option>
                                        )

                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Pincode<span class="asterisk">*</span></label>
                                <input type='text' className='form-control' placeholder='Pincode'
                                    value={Prospectpincode} maxLength={6}
                                    onChange={(e) => setProspectpincode(e.target.value)}
                                />
                            </div>
                        </div>

                    </div>
                    <button className='formBtn mr-3 mb-4 float-right' onClick={guardianinfoSubmit}>Next</button>
                </div>
            }

            {content3 &&
                <div className='innerlayout p-3'>
                    <div className='mb-4'>
                        <h3> Enrollment Details</h3>
                    </div>
                    <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-67px' }}>
                        <ul>
                            <li>
                                <button className='formBtn1' onClick={() => setContent(2)}><i className="fa-solid fa-angle-left"></i> Back</button>
                            </li>
                        </ul>
                    </div>
                    <label className='selectlabel' style={{ fontSize: '16px' }}>Billing Information</label>

                    <div className='row form-style mb-4'>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>First Name<span class="asterisk">*</span></label>
                                <input type='text' className='form-control' placeholder='First Name'
                                    value={Billingfirstname}
                                    onChange={(e) => setBillingfirstname(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>last Name<span class="asterisk">*</span></label>
                                <input type='text' className='form-control' placeholder='Last Name'
                                    value={Billinglastname}
                                    onChange={(e) => setBillinglastname(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Email<span class="asterisk">*</span></label>
                                <input type='text' disabled className='form-control' placeholder='Email'
                                    value={Billingemail}
                                    onChange={(e) => setBillingemail(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Mobile No.<span class="asterisk">*</span></label>
                                <input type='text' className='form-control' placeholder='Mobile No.'
                                    value={Billingphone} maxLength={10}
                                    onChange={(e) => setBillingphone(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Address 1<span class="asterisk">*</span></label>
                                <input type='text' className='form-control' placeholder='Address 1'
                                    value={Billingadd1}
                                    onChange={(e) => setBillingadd1(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Address 2</label>
                                <input type='text' className='form-control' placeholder='Address 2'
                                    value={Billingadd2}
                                    onChange={(e) => setBillingadd2(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Country<span class="asterisk">*</span></label>
                                <select className='form-control' value={Billingcountry}
                                    onChange={BillingCountryHandle}>
                                    <option value="">--Select country---</option>
                                    {countryList && countryList.map((list, i) => {
                                        return (
                                            <option key={i} value={list?.id}>{list?.name}</option>
                                        )

                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>State<span class="asterisk">*</span></label>
                                <select className='form-control' value={Billingstate}
                                    onChange={BillingStateHandle}>
                                    <option value="">--Select State---</option>
                                    {stateList && stateList.map((list, i) => {
                                        return (
                                            <option key={i} value={list?.state_id}>{list?.name}</option>
                                        )

                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>City<span class="asterisk">*</span></label>
                                <select className='form-control' value={Billingcity}
                                    onChange={(e) => setBillingcity(e.target.value)}>
                                    <option value="">--Select City---</option>
                                    {cityList && cityList.map((list, i) => {
                                        return (
                                            <option key={i} value={list?.city_id}>{list?.name}</option>
                                        )

                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Pincode<span class="asterisk">*</span></label>
                                <input type='text' className='form-control' placeholder='Pincode'
                                    value={Billingpincode} maxLength={6}
                                    onChange={(e) => setBillingpincode(e.target.value)}
                                />
                            </div>
                        </div>


                    </div>
                    <button className='formBtn mr-3 mb-4 float-right' onClick={billinginfoSubmit}>Next</button>
                </div>
            }

            {content4 &&
                <div className='innerlayout p-3'>
                    <div className='mb-4'>
                        <h3> Enrollment Details</h3>
                    </div>
                    <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-67px' }}>
                        <ul>
                            <li>
                                <button className='formBtn1' onClick={() => setContent(3)}><i className="fa-solid fa-angle-left"></i> Back</button>
                            </li>
                        </ul>
                    </div>

                    <label className='selectlabel' style={{ fontSize: '16px' }}>Guidelines Terms and Consent Forms</label>

                    <hr></hr>
                    <div className='row form-style mb-4'>


                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label className='selectlabel'>In Person Learning Guidelines</label>
                                <textarea type='text' disabled className='form-control' value={inpersontext} placeholder='Learning Center Guidelines'
                                ></textarea>
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label className='selectlabel'><b>Notes</b> :
                                </label>
                                <textarea type='text' disabled className='form-control guidelinesnotes' value={inpersonnotes} placeholder=''
                                ></textarea>
                            </div>
                        </div>

                        <div className='ml-4 mt-4'>
                            <input type='checkbox' value={inpersonchk} onChange={(e) => setinpersonchk(e.target.value)} /> I Accept All the Terms & Conditions <span class="asterisk">*</span>
                        </div>

                        <div className='mb-5 ml-5' style={{ width: '100px' }}>
                            <label className='selectlabel'>Initials<span class="asterisk">*</span></label>
                            <input value={inpersonsign} className='form-control' onChange={(e) => setinpersonsign(e.target.value)} />
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label className='selectlabel'>Online Learning Guidelines</label>
                                <textarea type='text' disabled className='form-control' value={onlinetext} placeholder='Mathnasium @home Learning Center Guidelines'
                                ></textarea>
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label className='selectlabel'><b>Notes</b> :
                                </label>
                                <textarea type='text' disabled className='form-control guidelinesnotes' value={onlinenotes} placeholder=''
                                ></textarea>
                            </div>
                        </div>

                        <div className='ml-4 mt-4'>
                            <input type='checkbox' value={onlinechk} onChange={(e) => setonlinechk(e.target.value)} /> I Accept All the Terms & Conditions <span class="asterisk">*</span>
                        </div>

                        <div className='mb-5 ml-5' style={{ width: '100px' }}>
                            <label className='selectlabel'>Initials<span class="asterisk">*</span></label>
                            <input value={onlinesign} className='form-control' onChange={(e) => setonlinesign(e.target.value)} />
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label className='selectlabel'>Media Release Form</label>
                                <textarea type='text' disabled className='form-control' value={mediatext} placeholder='Media Release Form'
                                ></textarea>
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label className='selectlabel'><b>Notes</b> :
                                </label>
                                <textarea type='text' disabled className='form-control guidelinesnotes' value={medianotes} placeholder=''
                                ></textarea>
                            </div>
                        </div>

                        <div className='d-flex mb-5 ml-5'>
                            <button className='mr-2 formBtn' style={{ height: '44px', marginTop: '14px' }}>Opt In</button>
                            <button className='formBtn' style={{ height: '44px', marginTop: '14px' }}>Opt Out</button>
                        </div>

                        <div className='ml-5 mt-4'>
                            <input type='checkbox' value={mediachk} onChange={(e) => setmediachk(e.target.value)} /> I Accept All the Terms & Conditions <span class="asterisk">*</span>
                        </div>
                        <div className='mb-5 ml-5' style={{ width: '100px' }}>
                            <label className='selectlabel'>Initials<span class="asterisk">*</span></label>
                            <input value={mediasign} className='form-control' onChange={(e) => setmediasign(e.target.value)} />
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label className='selectlabel'>Teacher Consent Form</label>
                                <textarea type='text' disabled className='form-control' value={teachertext} placeholder='Teacher Consent Form'
                                ></textarea>
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label className='selectlabel'><b>Notes</b> :
                                </label>
                                <textarea type='text' disabled className='form-control guidelinesnotes' value={teachernotes} placeholder=''
                                ></textarea>
                            </div>
                        </div>

                        <div className='d-flex mb-5 ml-5'>
                            <button className='mr-2 formBtn' style={{ height: '44px', marginTop: '14px' }}>Opt In</button>
                            <button className='formBtn' style={{ height: '44px', marginTop: '14px' }}>Opt Out</button>
                        </div>

                        <div className='ml-5 mt-4'>
                            <input type='checkbox' value={teacherchk} onChange={(e) => setteacherchk(e.target.value)} /> I Accept All the Terms & Conditions <span class="asterisk">*</span>
                        </div>

                        <div className='mb-5 ml-5' style={{ width: '100px' }}>
                            <label className='selectlabel'>Initials<span class="asterisk">*</span></label>
                            <input value={teachersign} className='form-control' onChange={(e) => setteachersign(e.target.value)} />
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label className='selectlabel'>Leave Unescorted Consent Guidelines</label>
                                <textarea type='text' disabled className='form-control' value={leavetext} placeholder='Leave Unescorted Consent Form'
                                ></textarea>
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label className='selectlabel'><b>Notes</b> :
                                </label>
                                <textarea type='text' disabled className='form-control guidelinesnotes' value={leavenotes} placeholder=''
                                ></textarea>
                            </div>
                        </div>

                        <div className='ml-5 mt-4'>
                            <input type='checkbox' value={leavechk} onChange={(e) => setleavechk(e.target.value)} /> I Accept All the Terms & Conditions <span class="asterisk">*</span>
                        </div>

                        <div className='mb-5 ml-5' style={{ width: '100px' }}>
                            <label className='selectlabel'>Initials<span class="asterisk">*</span></label>
                            <input value={leavesign} className='form-control' onChange={(e) => setleavesign(e.target.value)} />
                        </div>

                    </div>
                    <button className='formBtn mr-3 mb-4 float-right' onClick={termsinfoSubmit}>Next</button>
                </div>
            }

            {content5 &&
                <div className='innerlayout p-3'>
                    <div className='mb-4'>
                        <h3> Enrollment Details</h3>
                    </div>
                    <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-67px' }}>
                        <ul>
                            <li>
                                <button className='formBtn1' onClick={() => setContent(4)}><i className="fa-solid fa-angle-left"></i> Back</button>
                            </li>
                        </ul>
                    </div>
                    <label className='selectlabel' style={{ fontSize: '16px' }}>Service and Enrollment Cost Details</label>

                    <hr></hr>
                    <div className='row form-style mb-4'>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Select Subject<span class="asterisk">*</span></label>
                                <select className='form-control' value={subject}
                                    onChange={SubjectHandle}>
                                    <option value="">--Select Subject---</option>
                                    {subjectList && subjectList.map((item, i) => {
                                        return (
                                            <option key={i} value={item?.id} >{item?.subject_name}</option>
                                        )

                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Select Service<span class="asterisk">*</span></label>
                                <select className='form-control' value={servicename}
                                    onChange={(e) => ServiceHandle(e)}>
                                    <option value="">--Select Services---</option>
                                    {serviceList && serviceList.map((item, i) => {
                                        return (
                                            <option key={i} value={item?.id} >{item?.name}</option>
                                        )

                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Pricing<span class="asterisk">*</span></label>
                                <select className='form-control' value={pricename}
                                    onChange={(e) => PriceHandle(e)}>
                                    <option value="">--Select Price---</option>
                                    {priceList && priceList.map((item, i) => {
                                        return (
                                            <option key={i} value={item?.id} >{item?.name}</option>
                                        )

                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Total Session<span class="asterisk">*</span></label>
                                <input type='text' disabled className='form-control' placeholder='Total Session' value={session1}
                                    onChange={(e) => { setsession1(e.target.value) }}
                                />
                            </div>
                        </div>


                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Schedule Duration<span class="asterisk">*</span></label>
                                <select className='form-control' value={scheduleduration}
                                    onChange={(e) => { setscheduleduration(e.target.value) }}>
                                    <option value="">--Select Duration---</option>
                                    <option value="30">30 min.</option>
                                    <option value="60">60 min.</option>
                                    <option value="90">90 min.</option>
                                    <option value="120">120 min.</option>
                                    <option value="150">150 min.</option>
                                    <option value="180">180 min.</option>
                                    <option value="210">210 min.</option>
                                    <option value="240">240 min.</option>
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Price<span class="asterisk">*</span> </label>
                                <input type='text' disabled className='form-control' placeholder='Price' value={price1}
                                    onChange={(e) => { setprice1(e.target.value) }}
                                />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Recurring Start Date<span class="asterisk">*</span> </label>
                                <input type='date' className='form-control' placeholder='Recurring Start Date' value={contactstartdate}
                                    onChange={(e) => { setcontactstartdate(e.target.value) }}
                                />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Recurring End Date </label>
                                <input type='date' className='form-control' placeholder='Recurring End Date' value={contactenddate}
                                    onChange={(e) => { setcontactenddate(e.target.value) }}
                                />
                            </div>
                        </div>

                        <hr></hr>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Monthly Recurring<span class="asterisk">*</span> </label>
                                <input type='text' disabled className='form-control' placeholder='Monthly Recurring' value={recurring1}
                                    onChange={(e) => { setrecurring1(e.target.value) }}
                                />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Registration Fee<span class="asterisk">*</span></label>
                                <input type='text' className='form-control' placeholder='Registration Fee '
                                    value={enrollregfee} onChange={(e) => setenrollregfee(e.target.value)} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Prorated First Month Session<span class="asterisk">*</span> </label>
                                <input type='text' className='form-control' placeholder='Prorated First Month Session '
                                    value={enrolltotalsession} onChange={(e) => ProratedamountHandle(e.target.value)} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Prorated First Month Price<span class="asterisk">*</span> </label>
                                <input type='text' disabled className='form-control' placeholder='Prorated First Month Price'
                                    value={enrolltype} onChange={(e) => setenrolltype(e.target.value)} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Select Discount</label>
                                <select className='form-control' value={discount}
                                    onChange={(e) => DiscountHandle(e)}>
                                    <option value="">--Select Discount---</option>
                                    {discountList && discountList.map((item, i) => {
                                        return (
                                            <option key={i} value={item?.id} >{item?.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Assessment Fee<span class="asterisk">*</span> </label>
                                <input type='text' className='form-control' placeholder='Assessment Fee '
                                    value={enrollassessmentfee} onChange={(e) => setenrollassessmentfee(e.target.value)} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Discount Amount </label>
                                <input type='text' disabled className='form-control' placeholder='Amount' value={discountAmount}
                                    onChange={(e) => { setdiscountAmount(e.target.value) }}
                                />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Sub Total 1<span class="asterisk">*</span> </label>
                                <input type='text' disabled className='form-control' placeholder='Sub Total 1'
                                    value={Amount1} onChange={(e) => setAmount1(e.target.value)} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Discount Percentage </label>
                                <input type='text' disabled className='form-control' placeholder='Percentage' value={discountPercentage}
                                    onChange={(e) => { setdiscountPercentage(e.target.value) }}
                                />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Discount (for one time only) </label>
                                <input type='text' disabled className='form-control' placeholder='Discount' value={discountAmount}
                                    onChange={(e) => { setdiscountAmount(e.target.value) }}
                                />
                            </div>
                        </div>

                        {/* <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Discount </label>
                                <input type='text' className='form-control' placeholder='Discount' value={discountAmount}
                                    onChange={(e) => { setdiscountAmount(e.target.value) }}
                                />
                            </div>
                        </div> */}

                        {/* <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Late Payment Fee </label>
                                <input type='text' className='form-control' placeholder='Late Payment Fee '
                                    value={enrollremainings} onChange={(e) => setenrollremainings(e.target.value)} />
                            </div>
                        </div> */}

                        <div className='col-lg-6'>
                            <div className='form-group' style={{ marginTop: '40px', fontWeight: '600' }}>
                                <label className='selectlabel'> <u>Monthly Recurring Details</u> </label>
                            </div>
                        </div>

                        {/* <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Account Closing Fee </label>
                                <input type='text' className='form-control' placeholder='Account Closing Fee'
                                    value={enrollmonthlyamount} onChange={(e) => setenrollmonthlyamount(e.target.value)} />
                            </div>
                        </div> */}

                        {/* <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Manual Fee </label>
                                <input type='text' className='form-control' placeholder='Manual Fee '
                                    value={enrollmanualfee} onChange={(e) => setenrollmanualfee(e.target.value)} />
                            </div>
                        </div> */}

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Sub Total 2<span class="asterisk">*</span> </label>
                                <input type='text' disabled className='form-control' placeholder='Sub Total 2'
                                    value={Amount2} onChange={(e) => setAmount2(e.target.value)} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Price</label>
                                <input type='text' className='form-control' value={price1}
                                    onChange={(e) => setenrollstartdate(e.target.value)} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Payment Method</label>
                                <select className='form-control' value={paymentmethod}
                                    onChange={handlePaymentMethodChange}>
                                    <option value="" disabled>Payment Method</option>
                                    <option value="1">Card</option>
                                    <option value="2">Cash</option>
                                    <option value="3">Check</option>
                                    <option value="4" disabled={achaccepted == '0'}>ACH</option>
                                </select>
                            </div>
                        </div>

                        {paymentmethod === '4' && (
                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <label className='selectlabel'>ACH Discount</label>
                                    <input type='text' disabled className='form-control' value={ACHDiscountAmount}
                                        onChange={(e) => setenrollstartdate(e.target.value)} />
                                </div>
                            </div>
                        )}


                        {paymentmethod === '4' && (
                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <label className='selectlabel'>ACH Discount <input disabled style={{ width: '12%' }} value={achdiscountamount} /> %</label>
                                    <input type='text' className='form-control' placeholder='ACH Discount' value={ACHDiscountAmount}
                                        onChange={(e) => { setachdiscountamount(e.target.value) }}
                                    />
                                </div>
                            </div>
                        )}

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Total (Future Recurring Months)</label>
                                <input type='text' disabled className='form-control' value={monthlyprice}
                                    onChange={(e) => setmonthlyprice(e.target.value)} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='form-group'>
                                    <label className='selectlabel'>Today's Total<span class="asterisk">*</span> </label>
                                    <input type='text' className='form-control' placeholder='Todays Total '
                                        value={TodaysAmount} onChange={(e) => setTodaysAmount(e.target.value)} />
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 erolldetails' style={{ maxWidth: '47%' }}>
                            <span className='selectlabel'>Notes : </span><br></br>

                            <div className='form-group'>
                                <label className='selectlabel'>A/C Closing Fee <input disabled value={enrollmonthlyamount} onChange={(e) => setenrollmonthlyamount(e.target.value)} /></label><br></br>
                                <label className='selectlabel'>Late Fee <input disabled value={enrollremainings} onChange={(e) => setenrollremainings(e.target.value)} /></label><br></br>
                                <label className='selectlabel'>Manual Fee <input disabled value={enrollmanualfee} onChange={(e) => setenrollmanualfee(e.target.value)} /></label><br></br>
                            </div>
                        </div>

                    </div>
                    <button className='formBtn mr-3 mb-4 float-right' onClick={serviceinfoSubmit}>Confirm</button>
                </div>
            }

            <Modal show={show1} size="lg">
                <Modal.Header >
                    <Modal.Title>Add School</Modal.Title>
                    <button onClick={handleClose} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>School Name <span class="asterisk">*</span></label>
                                <input type='text' className='form-control' placeholder='School Name'
                                    value={schoolname}
                                    onChange={(e) => { setschoolname(e.target.value) }}
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Phone No.</label>
                                <input type='text' className='form-control' placeholder='Phone No.'
                                    value={schoolphone}
                                    onChange={(e) => { setschoolphone(e.target.value) }}
                                    maxLength="10"
                                    minLength="10"
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Email</label>
                                <input type='text' className='form-control' placeholder='Email'
                                    value={schoolemail}
                                    onChange={(e) => { setschoolemail(e.target.value) }}
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Principal/Headmaster Name</label>
                                <input type='text' className='form-control' placeholder='Name'
                                    value={schoolcontactperson}
                                    onChange={(e) => { setschoolcontactperson(e.target.value) }}
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Address</label>
                                <input type='text' className='form-control' placeholder='Address'
                                    value={schooladdress}
                                    onChange={(e) => { setschooladdress(e.target.value) }}
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Website</label>
                                <input type='text' className='form-control' placeholder='Website'
                                    value={schoolwebsite}
                                    onChange={(e) => { setschoolwebsite(e.target.value) }}
                                />
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={SubmitSchool}>Add</button>

                </Modal.Footer>
            </Modal>


        </div>
    )
}

export default StudentEnrollDetails